import { GasContributorUnitOperation } from '../gas-contributor-unit-operation';
import { SimulationVariable } from '../../simulation-variable';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { Case } from '../../case';
import { Quantity } from '../../../_config/quantity.enum';
import { ConstrainableObject } from '../../_interfaces/constrainable-object';
import { UnitOperationConstraints } from '../unit-operation-constraints';
import { SimulationVariableName } from '../../../_config/simulation-variable-name.enum';
import { KpiProvider } from '../../_case-study/kpi-provider';
import { ParameterProvider } from '../../_case-study/parameter-provider';

export class LightEndsRecoveryUnit
  extends GasContributorUnitOperation
  implements ConstrainableObject, KpiProvider, ParameterProvider
{
  category = unitOperationsConfig.lightEndsRecoveryUnit.key;

  butaneRecovery: SimulationVariable;
  removalFactor: SimulationVariable;

  constraints: UnitOperationConstraints;

  constructor(unitOperation: any | LightEndsRecoveryUnit, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override initValues(unitOperation: any) {
    super.initValues(unitOperation);

    this.butaneRecovery = new SimulationVariable(unitOperation.butaneRecovery, this.ownerCase, this);
    this.removalFactor = new SimulationVariable(unitOperation.removalFactor, this.ownerCase, this);

    this.initConstraints();
    this.setQuantities();
  }

  override setQuantities() {
    super.setQuantities();
    this.butaneRecovery.setQuantity(Quantity.PERCENT);
    this.removalFactor.setQuantity(Quantity.PERCENT);
    this.capacity.setQuantity(Quantity.MOLEFLOWRATE);
    this.minimumFlow.setQuantity(Quantity.MOLEFLOWRATE);
  }

  override setDefaultValues() {
    super.setDefaultValues();

    if (this.butaneRecovery.isUndefined()) {
      this.butaneRecovery.setDefaultValue(70);
    }

    if (this.removalFactor.isUndefined()) {
      this.removalFactor.setDefaultValue(99.5);
    }
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    this.capacity.setName(SimulationVariableName.CAPACITY);
    this.fuelGasHeatingValue.setName(SimulationVariableName.FUEL_GAS_HEATING_VALUE);
    this.fuelGasEmissionFactor.setName(SimulationVariableName.FUEL_GAS_EMISSION_FACTOR);
    this.energyFlow.setName(SimulationVariableName.ENERGY_FLOW);
    this.flowrate.setName(SimulationVariableName.FUEL_GAS_FLOWRATE);
    this.opexTotal.setName('Total OPEX');
    this.capexTotal.setName('Total CAPEX');
  }

  override dePersist(uo: any) {
    super.dePersist(uo);
    this.butaneRecovery = this.getSimulationVariableSafe(uo.butaneRecovery);
    this.removalFactor = this.getSimulationVariableSafe(uo.removalFactor);
    this.setQuantities();
    this.setDefaultValues();
    this.initConstraints();
  }

  // region Constraints
  initConstraints() {
    this.constraints = new UnitOperationConstraints();
    this.constraints.addConstraint('capacity', this.capacity);
    this.constraints.addConstraint('minimumFlow', this.minimumFlow);
  }

  constraintValueDefined(): boolean {
    return this.constraints.anyConstraintValueDefined();
  }

  constraintViolated(): boolean {
    const inletStream = this.ownerCase.filterSuncorMaterialStreams(s => {
      return s.outletUnitOperationId === this.id;
    })[0];

    return inletStream && this.constraints.constraintValueViolated('capacity', inletStream.volumetricFlowrate.value);
  }

  // endregion

  getAlternativeGhgIntensity(): SimulationVariable {
    return undefined;
  }

  getAvailableKpis(): Array<SimulationVariable> {
    return [
      this.ghgEmissions,
      this.steamUse900,
      this.steamMake900,
      this.steamMake600,
      this.steamUse600,
      this.steamMake150,
      this.steamUse150,
      this.steamMake50,
      this.steamUse50,
      this.fuelGasHeatingValue,
      this.fuelGasEmissionFactor,
      this.energyFlow,
      this.flowrate,
      this.opexTotal,
      this.capexTotal,
    ];
  }

  getAvailableParameters(): Array<SimulationVariable> {
    return undefined;
  }

  getAvailableParametricStudyParameters(): Array<SimulationVariable> {
    return [this.ghgIntensity, this.opexVar, this.opexGasDiesel, this.opexPower, this.opexFixed, this.capexAmortized];
  }
}
