import { generateUUID } from '../_utils/utils';

export class V230 {
  static addMissingCompoundsToGasCompositions() {
    const that = this;
    return (serializedCase: any) => {
      that.makeGasAssaysCompatible(serializedCase);
      that.makeFuelGasStreamCompositionsCompatible(serializedCase);
    };
  }

  private static makeGasAssaysCompatible(serializedCase: any) {
    const gasAssays = Object.values(serializedCase.otherBaseObjectPool).filter(
      (obj: any) => obj.category === 'gas-fluid-analysis'
    ) as any[];

    const svPool = serializedCase.simulationVariablePool;
    for (const assay of gasAssays) {
      // the original amount of compounds is 18, after this change, it will have 18
      if (assay.composition && Array.isArray(assay.composition) && assay.composition.length === 14) {
        const sampleVariable = svPool[assay.composition[0]];
        const sampleVariableData = {
          quantity: sampleVariable.quantity,
          unit: sampleVariable.unit,
          variableStatus: sampleVariable.variableStatus,
        };

        const ethylene = V230.getPlainVariable(sampleVariableData);
        const propylene = V230.getPlainVariable(sampleVariableData);
        const butylene = V230.getPlainVariable(sampleVariableData);
        const cyclopentane = V230.getPlainVariable(sampleVariableData);

        svPool[ethylene.id] = ethylene;
        svPool[propylene.id] = propylene;
        svPool[butylene.id] = butylene;
        svPool[cyclopentane.id] = cyclopentane;

        assay.composition.splice(8, 0, ethylene.id);
        assay.composition.splice(10, 0, propylene.id);
        assay.composition.splice(13, 0, butylene.id);
        assay.composition.splice(16, 0, cyclopentane.id);
      }
    }
  }

  private static makeFuelGasStreamCompositionsCompatible(serializedCase: any) {
    const fuelGasStreams = Object.values(serializedCase.materialStreamPool).filter(
      (obj: any) => obj.category === 'fuel-gas-material-stream'
    ) as any[];

    const svPool = serializedCase.simulationVariablePool;

    for (const fuelGasStream of fuelGasStreams) {
      if (
        fuelGasStream.composition &&
        Array.isArray(fuelGasStream.composition) &&
        fuelGasStream.composition.length === 14
      ) {
        const sampleVariable = svPool[fuelGasStream.composition[0]];
        const sampleVariableData = {
          quantity: sampleVariable.quantity,
          unit: sampleVariable.unit,
          variableStatus: sampleVariable.variableStatus,
        };

        const ethylene = V230.getPlainVariable(sampleVariableData);
        const propylene = V230.getPlainVariable(sampleVariableData);
        const butylene = V230.getPlainVariable(sampleVariableData);
        const cyclopentane = V230.getPlainVariable(sampleVariableData);

        svPool[ethylene.id] = ethylene;
        svPool[propylene.id] = propylene;
        svPool[butylene.id] = butylene;
        svPool[cyclopentane.id] = cyclopentane;

        fuelGasStream.composition.splice(8, 0, ethylene.id);
        fuelGasStream.composition.splice(10, 0, propylene.id);
        fuelGasStream.composition.splice(13, 0, butylene.id);
        fuelGasStream.composition.splice(16, 0, cyclopentane.id);
      }
    }
  }

  private static getPlainVariable(options: { quantity: string; unit: string; variableStatus: string }) {
    return {
      category: 'simulationVariable',
      id: generateUUID(),
      value: 0,
      unit: options.unit,
      quantity: options.quantity,
      isActive: true,
      variableStatus: options.variableStatus,
    };
  }
}
