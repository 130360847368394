export function getGasCompositionCompoundNames() {
  return [
    'CO',
    'CO2',
    'H2',
    'N2',
    'H2S',
    'SO2',
    'Methane',
    'Ethane',
    'Ethylene',
    'Propane',
    'Propylene',
    'IC4',
    'NC4',
    'Butylenes',
    'IC5',
    'NC5',
    'Cyclopentane',
    'C6+',
  ];
}
