import { BaseObject } from '../base-object';
import { Case } from '../case';
import { SuncorFluidAnalysis } from './suncor-fluid-analysis';
import { GasFluidAnalysis } from './gas-fluid-analysis';
import { isTypeUndefined } from '../../_utils/utils';
import { FluidAnalysis } from './fluid-analysis';
import { AssayType } from '../../_config/assay-type.enum';

export class AssayManager extends BaseObject {
  category = 'assayManager';
  fluidAnalyses: Array<FluidAnalysis> = [];
  currentAnalysisId: string;

  constructor(assayManager: any, ownerCase: Case) {
    super(assayManager.id, ownerCase);

    if (assayManager.fluidAnalyses instanceof Array) {
      for (const fluidAnalysis of assayManager.fluidAnalyses) {
        if (fluidAnalysis instanceof SuncorFluidAnalysis || fluidAnalysis instanceof GasFluidAnalysis) {
          this.fluidAnalyses.push(fluidAnalysis);
        }
      }
    }
    this.currentAnalysisId = assayManager.currentAnalysisId || '';
  }

  addFluidAnalysis(name: string, type: string): FluidAnalysis {
    let fa: FluidAnalysis;
    if (type === AssayType.GAS) {
      fa = new GasFluidAnalysis({}, this.ownerCase, this);
    } else {
      fa = new SuncorFluidAnalysis({}, this.ownerCase, this);
    }

    fa.assayName = name;
    fa.assayType = type || AssayType.CRUDE_OIL_BITUMEN;

    // fa.loadResourceData(resourceData.fluidAnalysis);

    this.fluidAnalyses.push(fa);
    this.ownerCase.addToPools(fa);
    fa.addSimVarsToPool();
    return fa;
  }

  removeFluidAnalysis(id: string) {
    const analyses = this.fluidAnalyses.filter(fa => {
      return fa.id === id;
    });

    if (analyses.length) {
      const index = this.fluidAnalyses.indexOf(analyses[0]);

      if (index > -1) {
        this.fluidAnalyses.splice(index, 1);
        this.ownerCase.removeFromPools(analyses[0]);
        this.ownerCase.removeFluidAnalysis(id);
        this.ownerCase.assayManager.currentAnalysisId = '';
      }
    }
  }

  findFluidAnalysisById(id: String): any {
    return this.fluidAnalyses.find(fa => fa.id === id);
  }

  override setSimulationVariablesOwner(): void {
    super.setSimulationVariablesOwner();
    for (const fa of this.fluidAnalyses) {
      fa.setSimulationVariablesOwner();
    }
  }

  getAssaysByCategory(type: string) {
    return this.fluidAnalyses.filter(fa => {
      return fa.assayType === type;
    });
  }

  getAssayByName(name: string) {
    if (!isTypeUndefined(name)) {
      return this.fluidAnalyses.find(fa => {
        return fa.assayName.toLowerCase() === name.toLowerCase();
      });
    }
    return undefined;
  }

  getAllAssays() {
    return this.fluidAnalyses;
  }

  override dePersist(persistedObj: any) {
    if (persistedObj.fluidAnalyses) {
      for (let i = 0; i < persistedObj.fluidAnalyses.length; i++) {
        this.fluidAnalyses[i] = this.ownerCase.getOtherBaseObject(persistedObj.fluidAnalyses[i]) as
          | SuncorFluidAnalysis
          | GasFluidAnalysis;
      }
    }
  }

  cloneAssay(id: string) {
    const assay = this.findFluidAnalysisById(id);
    let clonedAssay: FluidAnalysis;

    if (assay instanceof GasFluidAnalysis) {
      clonedAssay = new GasFluidAnalysis(assay, this.ownerCase, this);
    } else if (assay instanceof SuncorFluidAnalysis) {
      clonedAssay = new SuncorFluidAnalysis(assay, this.ownerCase, this);
    }

    clonedAssay.assayName = `${assay.assayName} cloned`;
    clonedAssay.id = (<any>window).uuid();

    const simvars = clonedAssay.getSimVars();
    simvars.forEach(simvar => {
      simvar.id = (<any>window).uuid();
    });

    if (clonedAssay instanceof SuncorFluidAnalysis) {
      clonedAssay.lightEndsCut.clearIds();
      clonedAssay.lightNaphthaCut.clearIds();
      clonedAssay.heavyNaphthaCut.clearIds();
      clonedAssay.distillateCut.clearIds();
      clonedAssay.LGOCut.clearIds();
      clonedAssay.HGOCut.clearIds();
      clonedAssay.VGOCut.clearIds();
      clonedAssay.HVGOCut.clearIds();
      clonedAssay.residCut.clearIds();
    }

    for (let i = 0; i < clonedAssay.composition.length; i++) {
      clonedAssay.composition[i].id = (<any>window).uuid();
    }
    clonedAssay.addSimVarsToPool();
    this.ownerCase.addToPools(clonedAssay);
    this.fluidAnalyses.push(clonedAssay);
    return clonedAssay;
  }

  public toJSON(): any {
    return {
      id: this.id,
      category: this.category,
      fluidAnalyses: this.fluidAnalyses.map(fa => fa.id),
      currentAnalysisId: this.currentAnalysisId,
    };
  }
}
