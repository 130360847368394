<table id="{{ tableId }}" class="table">
  <thead>
    <tr>
      <!--<th></th>-->
      <th>Name</th>
      <th>Type</th>
      <th>Actions</th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let fa of assayList" id="{{ fa.id }}">
      <td>{{ fa.assayName | titlecase }}</td>
      <td>{{ fa.assayType | titlecase }}</td>
      <td>
        <a id="editAssayButton{{ fa.id }}" (click)="onEditClicked(fa)" class="btn btn-primary btn-xs">Edit</a>
        <a (click)="onCloneClicked(fa)" class="btn btn-primary btn-xs" style="margin-left: 15px">Clone</a>
        <a (click)="onRemoveClicked(fa)" class="btn btn-danger btn-xs" style="margin-left: 15px">Delete</a>
      </td>
    </tr>
  </tbody>
</table>
