<h4>
  <a
    data-toggle="collapse"
    sfeCollapsePanelIcon
    href="#{{ idPrefix }}uo-lightEnds"
    aria-expanded="false"
    [attr.aria-controls]="'#uo-lightEnds'">
    <i class="fa fa-plus" aria-hidden="true"></i>
    Light Ends
  </a>
</h4>
<div id="{{ idPrefix }}uo-lightEnds" class="collapse">
  <div class="form-horizontal">
    <sob-cut
      [readOnly]="readonly"
      [cut]="suncorFluidAnalysis.lightEndsCut"
      [idPrefix]="idPrefix + 'lightEndsCut'"></sob-cut>
  </div>
</div>
<hr />
<h4>
  <a
    data-toggle="collapse"
    sfeCollapsePanelIcon
    href="#{{ idPrefix }}uo-lightNaphtha"
    aria-expanded="false"
    [attr.aria-controls]="'#uo-lightNaphtha'">
    <i class="fa fa-plus" aria-hidden="true"></i>
    Light Naphtha
  </a>
</h4>
<div id="{{ idPrefix }}uo-lightNaphtha" class="collapse">
  <div class="form-horizontal">
    <sob-cut
      [readOnly]="readonly"
      [cut]="suncorFluidAnalysis.lightNaphthaCut"
      [idPrefix]="idPrefix + 'lightNaphthaCut'"></sob-cut>
  </div>
</div>
<hr />
<h4>
  <a
    data-toggle="collapse"
    sfeCollapsePanelIcon
    href="#{{ idPrefix }}uo-heavyNaphtha"
    aria-expanded="false"
    [attr.aria-controls]="'#uo-heavyNaphtha'">
    <i class="fa fa-plus" aria-hidden="true"></i>
    Heavy Naphtha
  </a>
</h4>
<div id="{{ idPrefix }}uo-heavyNaphtha" class="collapse">
  <div class="form-horizontal">
    <sob-cut
      [readOnly]="readonly"
      [cut]="suncorFluidAnalysis.heavyNaphthaCut"
      [idPrefix]="idPrefix + 'heavyNaphthaCut'"></sob-cut>
  </div>
</div>
<hr />
<h4>
  <a
    data-toggle="collapse"
    sfeCollapsePanelIcon
    href="#{{ idPrefix }}uo-distillate"
    aria-expanded="false"
    [attr.aria-controls]="'#uo-distillate'">
    <i class="fa fa-plus" aria-hidden="true"></i>
    Distillate
  </a>
</h4>
<div id="{{ idPrefix }}uo-distillate" class="collapse">
  <div class="form-horizontal">
    <sob-cut
      [readOnly]="readonly"
      [cut]="suncorFluidAnalysis.distillateCut"
      [idPrefix]="idPrefix + 'distillateCut'"></sob-cut>
  </div>
</div>
<hr />
<h4>
  <a
    data-toggle="collapse"
    sfeCollapsePanelIcon
    href="#{{ idPrefix }}uo-lgo"
    aria-expanded="false"
    [attr.aria-controls]="'#uo-lgo'">
    <i class="fa fa-plus" aria-hidden="true"></i>
    LGO
  </a>
</h4>
<div id="{{ idPrefix }}uo-lgo" class="collapse">
  <div class="form-horizontal">
    <sob-cut [readOnly]="readonly" [cut]="suncorFluidAnalysis.LGOCut" [idPrefix]="idPrefix + 'LGOCut'"></sob-cut>
  </div>
</div>
<hr />
<h4>
  <a
    data-toggle="collapse"
    sfeCollapsePanelIcon
    href="#{{ idPrefix }}uo-hgo"
    aria-expanded="false"
    [attr.aria-controls]="'#uo-hgo'">
    <i class="fa fa-plus" aria-hidden="true"></i>
    HGO
  </a>
</h4>
<div id="{{ idPrefix }}uo-hgo" class="collapse">
  <div class="form-horizontal">
    <sob-cut [readOnly]="readonly" [cut]="suncorFluidAnalysis.HGOCut" [idPrefix]="idPrefix + 'HGOCut'"></sob-cut>
  </div>
</div>
<hr />
<h4>
  <a
    data-toggle="collapse"
    sfeCollapsePanelIcon
    href="#{{ idPrefix }}uo-vgo"
    aria-expanded="false"
    [attr.aria-controls]="'#uo-vgo'">
    <i class="fa fa-plus" aria-hidden="true"></i>
    VGO
  </a>
</h4>
<div id="{{ idPrefix }}uo-vgo" class="collapse">
  <div class="form-horizontal">
    <sob-cut [readOnly]="readonly" [cut]="suncorFluidAnalysis.VGOCut" [idPrefix]="idPrefix + 'VGOCut'"></sob-cut>
  </div>
</div>
<hr />
<h4>
  <a
    data-toggle="collapse"
    sfeCollapsePanelIcon
    href="#{{ idPrefix }}uo-hvgo"
    aria-expanded="false"
    [attr.aria-controls]="'#uo-hvgo'">
    <i class="fa fa-plus" aria-hidden="true"></i>
    HVGO
  </a>
</h4>
<div id="{{ idPrefix }}uo-hvgo" class="collapse">
  <div class="form-horizontal">
    <sob-cut [readOnly]="readonly" [cut]="suncorFluidAnalysis.HVGOCut" [idPrefix]="idPrefix + 'HVGOCut'"></sob-cut>
  </div>
</div>
<hr />
<h4>
  <a
    data-toggle="collapse"
    sfeCollapsePanelIcon
    href="#{{ idPrefix }}uo-resid"
    aria-expanded="false"
    [attr.aria-controls]="'#uo-resid'">
    <i class="fa fa-plus" aria-hidden="true"></i>
    Resid
  </a>
</h4>
<div id="{{ idPrefix }}uo-resid" class="collapse">
  <div class="form-horizontal">
    <sob-cut [readOnly]="readonly" [cut]="suncorFluidAnalysis.residCut" [idPrefix]="idPrefix + 'residCut'"></sob-cut>
  </div>
</div>
<hr />
