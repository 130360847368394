import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CoreService } from '../../../_services/core.service';
import { GasFluidAnalysis } from '../../../_models/_fluid/gas-fluid-analysis';
import { FuelGasSourceInformationStream } from '../../../_models/_unit-operations/fuel-gas/fuel-gas-source-information-stream';
import { isTypeUndefined } from '../../../_utils/utils';

@Component({
  selector: 'sob-fuel-gas-source-information-stream-form',
  templateUrl: './fuel-gas-source-information-stream-form.component.html',
  styleUrls: ['./fuel-gas-source-information-stream-form.component.css'],
})
export class FuelGasSourceInformationStreamFormComponent implements OnInit {
  @Input() informationStream: FuelGasSourceInformationStream;
  @Input() informationStreamFormGroup: UntypedFormGroup;
  currentAnalysis: GasFluidAnalysis;
  currentUnitOp: any;

  constructor(private coreService: CoreService) {}

  ngOnInit(): void {
    this.getGasAnalysis();
  }

  get fluidAnalyses() {
    return this.coreService.currentCase.assayManager.getAssaysByCategory('Gas');
  }

  get gasAnalysisFormControl() {
    return this.informationStreamFormGroup.get('gasAnalysis');
  }

  // TODO: get the gas analysis for gas export from the attached stream
  getGasAnalysis() {
    this.gasAnalysisFormControl.disable();
    this.currentUnitOp = this.coreService.currentCase.getUnitOperation(this.informationStream.providerUnitOperationId);
    const gasAnalysis = this.coreService.currentCase.assayManager.findFluidAnalysisById(
      this.currentUnitOp.gasAnalysisId
    );

    if (isTypeUndefined(gasAnalysis)) {
      this.gasAnalysisFormControl.setValue('');
    } else {
      this.gasAnalysisFormControl.setValue(gasAnalysis.assayName);
    }
  }
}
