import { Case } from '../case';
import { BaseObject } from '../base-object';
import { SimulationVariable } from '../simulation-variable';
import { ReadyStatus } from '../../_config/ready-status.enum';
import { BoundedObject } from '../bounded-object';

export abstract class FluidAnalysis extends BaseObject implements BoundedObject {
  assayType: string;
  assayName: string;
  composition: Array<SimulationVariable>;

  constructor(fluidAnalysis: any, ownerCase: Case) {
    super(fluidAnalysis.id, ownerCase);
  }

  // non-serializable objects, helpers for composition validation
  upperBound: number;
  lowerBound: number;
  readyStatus: ReadyStatus;
  quantity: string;
}
