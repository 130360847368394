import { Injectable } from '@angular/core';
import * as go from 'gojs';
import { FlowsheetDiagramService } from './flowsheet-diagram.service';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { GoJsDiagramUtils } from './go-js-diagram-utils';
import { OtherDiagramObject } from '../../_config/other-diagram.object';

@Injectable()
export class FlowsheetDiagramTemplateProvider {
  $: any = go.GraphObject.make;

  constructor(private flowsheetDiagramService: FlowsheetDiagramService, private gojsDiagramUtils: GoJsDiagramUtils) {}

  // region diagram templates
  getOverviewTemplate() {
    return this.$(go.Overview, 'overview');
  }

  getDiagramTemplate() {
    return this.$(go.Diagram, 'flowsheet-diagram', {
      allowCopy: false,
      initialContentAlignment: go.Spot.Center,
      LinkDrawn: e => {
        this.flowsheetDiagramService.linkDrawn(e);
      },
      // 'undoManager.isEnabled': true,
      // 'partRotated': nodeRotated,
      'toolManager.hoverDelay': 60,
      'toolManager.mouseWheelBehavior': go.ToolManager.WheelScroll,
      ExternalObjectsDropped: e => {
        const it = e.subject.iterator;

        while (it.next()) {
          if (it.value.data.category === OtherDiagramObject.DIAGRAM_COMMENT) {
            const diagram = it.value.diagram as go.Diagram;
            const textBlock = it.value.findObject('TEXT') as go.TextBlock;
            diagram.commandHandler.editTextBlock(textBlock);
            continue;
          }

          // should we calculate the name for the node here??
          this.flowsheetDiagramService.nodeCreated(it.value.data);
        }
      },
      SelectionDeleting: e => {
        const it = e.subject.iterator;

        // when only comments are deleted, do not clear results
        let clearResults = false;

        while (it.next()) {
          const part = it.value;

          if (!part.deletable || part.data.category === OtherDiagramObject.DIAGRAM_COMMENT) {
            continue;
          }

          // if this part is executed, it means that results should be cleared
          clearResults = true;
          if (part instanceof go.Node) {
            this.flowsheetDiagramService.nodeDeleted(part.data.id);
          } else if (part instanceof go.Link) {
            this.flowsheetDiagramService.linkDeleted(part.data);
          }
        }

        if (clearResults) {
          this.flowsheetDiagramService.clearCaseResults();
        }
      },
      SelectionMoved: e => {
        const it = e.subject.iterator;

        while (it.next()) {
          const part = it.value;

          if (part instanceof go.Node) {
            this.flowsheetDiagramService.nodeDragged(part.data);
          }
        }
      },
    });
  }

  createConstraintTooltip() {
    return this.$(
      go.Adornment,
      'Auto',
      this.$(go.Shape, {
        fill: '#CEF6F5',
        stroke: 'transparent',
      }),
      this.$(
        go.Panel,
        'Vertical',
        this.$(
          go.TextBlock,
          { margin: new go.Margin(5, 0, 0, 0), alignment: go.Spot.Center, font: 'bold 12px sans-serif' },
          new go.Binding('text', '', d => d.name)
        ),
        this.$(
          go.TextBlock,
          { margin: new go.Margin(5, 0, 0, 5), alignment: go.Spot.Left },
          new go.Binding('text', '', d => (d.constraintInfo.constraintValueDefined ? d.constraintInfo.statusText : '')),
          new go.Binding('visible', '', d => d.constraintInfo.constraintValueDefined)
        ),

        this.$(
          go.Panel,
          'Vertical',
          { margin: new go.Margin(3) },
          new go.Binding('itemArray', '', d => d.constraintInfo.valuesTextInfo),
          {
            itemTemplate: this.$(
              go.Panel,
              'Table',
              {
                defaultStretch: go.GraphObject.Horizontal,
                margin: new go.Margin(3, 0, 0, 5),
                alignment: go.Spot.Left,
              },
              this.$(
                go.Panel,
                'TableRow',
                { row: 0, alignment: go.Spot.Left },
                this.$(
                  go.TextBlock,
                  { column: 0, margin: new go.Margin(3, 7, 0, 0), alignment: go.Spot.Left },
                  new go.Binding('text', 'variableName')
                ),
                this.$(
                  go.TextBlock,
                  { column: 1, margin: new go.Margin(3, 7, 0, 0), alignment: go.Spot.Left },
                  new go.Binding('text', 'variableValue')
                ),
                this.$(go.TextBlock, { column: 2, margin: new go.Margin(3, 7, 0, 0) }, new go.Binding('text', 'unit')),
                new go.Binding('visible', '', d => !!d.variableValue)
              ),

              this.$(
                go.Panel,
                'TableRow',
                { row: 1, alignment: go.Spot.Left },
                this.$(
                  go.TextBlock,
                  { column: 0, margin: new go.Margin(3, 7, 0, 0), alignment: go.Spot.Left },
                  new go.Binding('text', 'constraintName')
                ),
                this.$(
                  go.TextBlock,
                  { column: 1, margin: new go.Margin(3, 7, 0, 0), alignment: go.Spot.Left },
                  new go.Binding('text', 'constraintValue')
                ),
                this.$(go.TextBlock, { column: 2, margin: new go.Margin(3, 7, 0, 0) }, new go.Binding('text', 'unit')),
                new go.Binding('visible', '', d => !!d.constraintValue && !!d.variableValue)
              )
            ),
          }
        )
      )
    );
  }

  // region linkTemplates
  getLinkTemplate() {
    return this.$(
      go.Link,
      {
        routing: go.Link.Orthogonal,
        corner: 2,
        reshapable: true,
        adjusting: go.Link.End,
        layerName: 'Background',
      },
      new go.Binding('points').makeTwoWay(),
      this.$(
        go.Shape,
        new go.Binding('stroke', '', d => {
          return d.dashedLine ? '#777' : d.streamColor;
        }),

        new go.Binding('strokeWidth', '', d => {
          return d.dashedLine ? 1 : 2;
        }),

        new go.Binding('strokeDashArray', '', d => {
          return d.dashedLine ? [6, 3] : null;
        })
      ),
      this.$(
        go.Shape,
        { toArrow: 'OpenTriangle' },
        new go.Binding('stroke', '', d => {
          return d.dashedLine ? '#777' : d.streamColor;
        }).makeTwoWay(),

        new go.Binding('strokeWidth', '', d => {
          return d.dashedLine ? 1 : 2;
        })
      ),
      this.$(
        go.Picture,
        { width: 25, height: 25, source: unitOperationsConfig.recycleStream.diagramIcon },
        new go.Binding('visible', '', link => {
          return !!link.isRecycleStream;
        })
      ),
      {
        doubleClick: (e, link) => {
          this.flowsheetDiagramService.doubleClickOnLink(link.data.id);
        },

        toolTip: this.gojsDiagramUtils.createLinkToolTip(),

        selectionAdornmentTemplate: this.$(
          go.Adornment,
          this.$(go.Shape, { isPanelMain: true, stroke: 'dodgerblue', strokeWidth: 2 }),
          this.$(go.Shape, { toArrow: 'OpenTriangle', strokeWidth: 2, stroke: 'dodgerblue' })
        ),
      }
    );
  }

  getWaterLinkTemplate() {
    return this.$(
      go.Link,
      {
        routing: go.Link.Orthogonal,
        corner: 2,
        reshapable: true,
        adjusting: go.Link.End,
        layerName: 'Background',
      },
      new go.Binding('points').makeTwoWay(),

      this.$(
        go.Shape,
        new go.Binding('stroke', '', d => {
          return d.dashedLine ? '#777' : d.streamColor;
        }),

        new go.Binding('strokeWidth', '', d => {
          return d.dashedLine ? 1 : 2;
        }),

        new go.Binding('strokeDashArray', '', d => {
          return d.dashedLine ? [6, 3] : null;
        })
      ),
      this.$(
        go.Shape,
        { toArrow: 'OpenTriangle' },

        new go.Binding('stroke', '', d => {
          return d.dashedLine ? '#777' : d.streamColor;
        }).makeTwoWay(),

        new go.Binding('strokeWidth', '', d => {
          return d.dashedLine ? 1 : 2;
        })
      ),
      this.$(
        go.Picture,
        { width: 25, height: 25, source: unitOperationsConfig.recycleStream.diagramIcon },
        new go.Binding('visible', '', link => {
          return !!link.isRecycleStream;
        })
      ),
      {
        doubleClick: (e, link) => {
          this.flowsheetDiagramService.doubleClickOnWaterLink(link.data.id);
        },

        toolTip: this.gojsDiagramUtils.createWaterLinkToolTip(),

        selectionAdornmentTemplate: this.$(
          go.Adornment,
          this.$(go.Shape, { isPanelMain: true, stroke: 'dodgerblue', strokeWidth: 2 }),
          this.$(go.Shape, { toArrow: 'OpenTriangle', strokeWidth: 2, stroke: 'dodgerblue' })
        ),
      }
    );
  }

  getFuelGasLinkTemplate() {
    return this.$(
      go.Link,
      {
        routing: go.Link.Orthogonal,
        corner: 2,
        reshapable: true,
        adjusting: go.Link.End,
        layerName: 'Background',
      },
      new go.Binding('points').makeTwoWay(),

      this.$(
        go.Shape,
        new go.Binding('stroke', '', d => {
          return d.dashedLine ? '#777' : d.streamColor;
        }),

        new go.Binding('strokeWidth', '', d => {
          return d.dashedLine ? 1 : 2;
        }),

        new go.Binding('strokeDashArray', '', d => {
          return d.dashedLine ? [6, 3] : null;
        })
      ),
      this.$(
        go.Shape,
        { toArrow: 'OpenTriangle' },

        new go.Binding('stroke', '', d => {
          return d.dashedLine ? '#777' : d.streamColor;
        }).makeTwoWay(),

        new go.Binding('strokeWidth', '', d => {
          return d.dashedLine ? 1 : 2;
        })
      ),
      this.$(
        go.Picture,
        { width: 25, height: 25, source: unitOperationsConfig.recycleStream.diagramIcon },
        new go.Binding('visible', '', link => {
          return !!link.isRecycleStream;
        })
      ),
      {
        doubleClick: (e, link) => {
          this.flowsheetDiagramService.doubleClickOnFuelGasLink(link.data.id);
        },

        toolTip: this.gojsDiagramUtils.createFuelGasLinkToolTip(),

        selectionAdornmentTemplate: this.$(
          go.Adornment,
          this.$(go.Shape, { isPanelMain: true, stroke: 'dodgerblue', strokeWidth: 2 }),
          this.$(go.Shape, { toArrow: 'OpenTriangle', strokeWidth: 2, stroke: 'dodgerblue' })
        ),
      }
    );
  }
  // endregion

  getContextMenuTemplate(): any {
    return this.$(
      go.Adornment,
      'Vertical',
      this.$(
        'ContextMenuButton',
        this.$(go.TextBlock, 'Undo'),
        {
          click: e => {
            e.diagram.commandHandler.undo();
          },
        },
        new go.Binding('visible', '', o => {
          return o.diagram.commandHandler.canUndo();
        }).ofObject()
      ),
      this.$(
        'ContextMenuButton',
        this.$(go.TextBlock, 'Redo'),
        {
          click: e => {
            e.diagram.commandHandler.redo();
          },
        },
        new go.Binding('visible', '', o => {
          return o.diagram.commandHandler.canRedo();
        }).ofObject()
      )
    );
  }

  getModelTemplate(): any {
    return this.$(go.GraphLinksModel, {
      linkFromPortIdProperty: 'fromPort', // required information:
      linkToPortIdProperty: 'toPort', // identifies data property names
      nodeDataArray: [],
      linkDataArray: [],
      linkKeyProperty: 'linkKey',
    });
  }

  // endregion

  // region unit operation templates
  getDiluentSourceTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),

        this.$(
          go.Panel,
          'Horizontal',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'right', marginTop: 6 }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.diluentSource.diagramIcon,
            }),
            this.$(go.Shape, 'Rectangle', { width: 40, height: 40, fill: 'Transparent', stroke: null }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'out',
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getTopRightReadinessIndicator()
          )
        )
      ),

      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getMineTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Horizontal',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'right', marginTop: 6 }),

          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.mine.diagramIcon,
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'out',
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getTopRightReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getMixerTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
        // dragComputation: AvoidOverlapHelper.avoidNodeOverlap,
        linkConnected: (node, link, port) => {
          if (port.portId === 'in' && node.findLinksInto().count > 2) {
            const icon = node.findObject('ICON');
            const inlet = node.findObject('IN');
            const outlet = node.findObject('OUT');

            const proportion = (icon.height + 5) / icon.height;
            icon.width *= proportion;
            icon.height *= proportion;

            inlet.width *= proportion;
            inlet.height *= proportion;

            outlet.width *= proportion;
            outlet.height *= proportion;
          }
        },

        linkDisconnected: (node, link, port) => {
          if (port.portId === 'in' && node.findLinksInto().count >= 2) {
            const icon = node.findObject('ICON');
            const inlet = node.findObject('IN');
            const outlet = node.findObject('OUT');

            const proportion = (icon.height - 5) / icon.height;
            icon.width *= proportion;
            icon.height *= proportion;

            inlet.width *= proportion;
            inlet.height *= proportion;

            outlet.width *= proportion;
            outlet.height *= proportion;
          }
        },
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),

        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'right' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(22, 28),
              source: unitOperationsConfig.mixer.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              portId: 'in',
              name: 'IN',
              width: 4,
              height: 39,
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(0.88, 0.5),
              portId: 'out',
              name: 'OUT',
              fromMaxLinks: 1,
              width: 6,
              height: 4,
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getTopRightReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getCommodityTankTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
        // dragComputation: AvoidOverlapHelper.avoidNodeOverlap,
        linkConnected: (node, link, port) => {
          if (port.portId === 'in' && node.findLinksInto().count > 2) {
            const icon = node.findObject('ICON');
            const inlet = node.findObject('IN');

            const proportion = (icon.height + 5) / icon.height;
            icon.width *= proportion;
            icon.height *= proportion;

            inlet.width *= proportion;
            inlet.height *= proportion;
          }
        },

        linkDisconnected: (node, link, port) => {
          if (port.portId === 'in' && node.findLinksInto().count >= 2) {
            const icon = node.findObject('ICON');
            const inlet = node.findObject('IN');

            const proportion = (icon.height - 5) / icon.height;
            icon.width *= proportion;
            icon.height *= proportion;

            inlet.width *= proportion;
            inlet.height *= proportion;
          }
        },
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              source: unitOperationsConfig.commodityTank.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              portId: 'in',
              name: 'IN',
              width: 4,
              height: 39,
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.getCenterRightReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getOffshoreTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Horizontal',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'right', marginTop: 6 }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.offshore.diagramIcon,
            }),
            this.$(go.Shape, 'Circle', { width: 40, height: 40, fill: 'Transparent', stroke: null }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'out',
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getTopRightReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getPipeTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
        // dragComputation: AvoidOverlapHelper.avoidNodeOverlap
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center', marginRight: 6 }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 0,
              source: unitOperationsConfig.pipe.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'out',
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getBottomCenterReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      {
        toolTip: this.createConstraintTooltip(),
      }
    );
  }

  getBitumenConversionTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
        // dragComputation: AvoidOverlapHelper.avoidNodeOverlap
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              source: unitOperationsConfig.bitumenConversion.diagramIcon,
            }),
            this.$(go.Shape, 'Circle', { width: 40, height: 40, fill: 'Transparent', stroke: null }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              portId: 'out',
              width: 4,
              height: 39,
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getTopCenterReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getRefineryTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
        // dragComputation: AvoidOverlapHelper.avoidNodeOverlap
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),

        this.$(
          go.Panel,
          'Horizontal',
          this.$(
            go.Panel,
            'Spot',

            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.refinery.diagramIcon,
            }),

            this.$(go.Shape, 'Circle', { width: 40, height: 40, fill: 'Transparent', stroke: null }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'Train 1',
            }),
            this.gojsDiagramUtils.getTopLeftReadinessIndicator()
          ),

          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'left', marginTop: 6 })
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      {
        toolTip: this.createConstraintTooltip(),
      }
    );
  }

  getMultipleInletRefineryTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
        // dragComputation: AvoidOverlapHelper.avoidNodeOverlap
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Horizontal',
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.refinery.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.25),
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'Train 1',
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.75),
              toMaxLinks: 1,
              portId: 'train2',
              toolTip: 'Train 2',
            }),
            this.gojsDiagramUtils.getTopRightReadinessIndicator()
          ),
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'left', marginTop: 6 })
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      {
        toolTip: this.createConstraintTooltip(),
      }
    );
  }

  getRenewableTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
        // dragComputation: AvoidOverlapHelper.avoidNodeOverlap
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.$(go.Panel, 'Horizontal', this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' })),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              source: unitOperationsConfig.suncorRenewableBlock.diagramIcon,
            }),
            this.gojsDiagramUtils.getCenterRightReadinessIndicator()
          )
        )
      ),

      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getSagdTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
        // dragComputation: AvoidOverlapHelper.avoidNodeOverlap
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Horizontal',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'right', marginTop: 6 }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.sagd.diagramIcon,
            }),
            // this.gojsDiagramUtils.getConnectionIndicatorTemplate(),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'out',
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getTopRightReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getUpgraderInletTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
        deletable: true,
        // dragComputation: AvoidOverlapHelper.avoidNodeOverlap
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Horizontal',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'right', marginTop: 6 }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.upgraderInlet.diagramIcon,
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'out',
              toolTip: 'Out',
            }),
            this.$(
              go.Panel,
              'Vertical',
              { alignment: new go.Spot(0, 0.7), width: 20, height: 20 },
              this.$(go.Shape, { figure: 'Rectangle', width: 12, height: 2, stroke: '#666', fill: '#666' })
            ),
            this.gojsDiagramUtils.getTopRightReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getSplitterTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
        // dragComputation: AvoidOverlapHelper.avoidNodeOverlap,
        linkConnected: (node, link, port) => {
          if (port.portId === 'out' && node.findLinksOutOf().count > 2) {
            const icon = node.findObject('ICON');
            const inlet = node.findObject('IN');
            const outlet = node.findObject('OUT');

            const proportion = (icon.height + 5) / icon.height;
            icon.width *= proportion;
            icon.height *= proportion;

            inlet.width *= proportion;
            inlet.height *= proportion;

            outlet.width *= proportion;
            outlet.height *= proportion;
          }
        },

        linkDisconnected: (node, link, port) => {
          if (port.portId === 'out' && node.findLinksOutOf().count >= 2) {
            const icon = node.findObject('ICON');
            const inlet = node.findObject('IN');
            const outlet = node.findObject('OUT');

            const proportion = (icon.height - 5) / icon.height;
            icon.width *= proportion;
            icon.height *= proportion;

            inlet.width *= proportion;
            inlet.height *= proportion;

            outlet.width *= proportion;
            outlet.height *= proportion;
          }
        },
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.$(go.Panel, 'Horizontal', this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' })),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(22, 28),
              source: unitOperationsConfig.splitter.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0.1, 0.5),
              portId: 'in',
              name: 'IN',
              height: 4,
              width: 4,
              toolTip: 'In',
              toMaxLinks: 1,
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              portId: 'out',
              name: 'OUT',
              width: 4,
              height: 39,
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getTopLeftReadinessIndicator()
          )
        )
      ),

      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getProductTankTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
        // dragComputation: AvoidOverlapHelper.avoidNodeOverlap,
        linkConnected: (node, link, port) => {
          if (port.portId === 'out' && node.findLinksOutOf().count > 2) {
            const icon = node.findObject('ICON');
            const inlet = node.findObject('IN');
            const outlet = node.findObject('OUT');

            const proportion = (icon.height + 5) / icon.height;
            icon.width *= proportion;
            icon.height *= proportion;

            inlet.width *= proportion;
            inlet.height *= proportion;

            outlet.width *= proportion;
            outlet.height *= proportion;
          }
        },

        linkDisconnected: (node, link, port) => {
          if (port.portId === 'out' && node.findLinksOutOf().count >= 2) {
            const icon = node.findObject('ICON');
            const inlet = node.findObject('IN');
            const outlet = node.findObject('OUT');

            const proportion = (icon.height - 5) / icon.height;
            icon.width *= proportion;
            icon.height *= proportion;

            inlet.width *= proportion;
            inlet.height *= proportion;

            outlet.width *= proportion;
            outlet.height *= proportion;
          }
        },
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              source: unitOperationsConfig.productTank.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              portId: 'in',
              name: 'IN',
              width: 4,
              height: 39,
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              portId: 'out',
              name: 'OUT',
              width: 4,
              height: 39,
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getBottomCenterReadinessIndicator()
          )
        )
      ),

      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getDRUTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
        // dragComputation: AvoidOverlapHelper.avoidNodeOverlap,
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.dru.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              portId: 'in',
              name: 'IN',
              toolTip: 'In',
              toMaxLinks: 1,
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              portId: 'out',
              name: 'OUT',
              width: 4,
              height: 39,
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getBottomCenterReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getVACTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
        // dragComputation: AvoidOverlapHelper.avoidNodeOverlap,
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.vac.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              portId: 'in',
              name: 'IN',
              toolTip: 'In',
              toMaxLinks: 1,
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              portId: 'out',
              name: 'OUT',
              width: 4,
              height: 39,
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getBottomCenterReadinessIndicator()
          )
        )
      ),

      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getThirdPartyRefineryTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Horizontal',
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              source: unitOperationsConfig.thirdPartyRefinery.diagramIcon,
              margin: 5,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.getTopLeftReadinessIndicator()
          ),
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'left', marginTop: 6 })
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getGenericSinkTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Horizontal',
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              source: unitOperationsConfig.genericSink.diagramIcon,
            }),
            this.$(go.Shape, 'Circle', { width: 40, height: 40, fill: 'Transparent', stroke: null }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.getTopLeftReadinessIndicator()
          ),
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'left', marginTop: 6 })
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getThirdPartySourceTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
        // dragComputation: AvoidOverlapHelper.avoidNodeOverlap
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Horizontal',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'right', marginTop: 6 }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.diluentSource.diagramIcon,
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'out',
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getTopRightReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),

      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getUpgraderTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.$(go.Panel, 'Horizontal', this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' })),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              source: unitOperationsConfig.upgrader.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              toMaxLinks: 1,
              portId: 'inlet1',
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(0.95, 0.25),
              fromMaxLinks: 1,
              portId: 'coker',
              toolTip: 'Coker',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(0.95, 0.75),
              fromMaxLinks: 1,
              portId: 'hydroTreated',
              toolTip: 'Hydrotreated',
            }),
            this.gojsDiagramUtils.getBottomCenterReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      {
        toolTip: this.createConstraintTooltip(),
      }
    );
  }

  // TODO delete this? is this even used?
  getMultipleInletUpgraderTemplate() {
    return this.$(
      go.Node,
      'Vertical',
      {
        locationObjectName: 'BODY',
        locationSpot: go.Spot.Center,
        selectionObjectName: 'BODY',
      },
      // the body
      this.$(
        go.Panel,
        'Horizontal',
        {
          name: 'name',
        },
        this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' })
      ),
      this.$(
        go.Panel,
        'Table',
        this.$(
          go.Panel,
          'Auto',
          {
            row: 1,
            column: 2,
            name: 'BODY',
            stretch: go.GraphObject.Fill,
          },
          this.$(go.Shape, 'Rectangle', {
            fill: '#fff',
            stroke: null,
            strokeWidth: 0,
            minSize: new go.Size(40, 40),
          }),
          this.$(go.Picture, {
            name: 'ICON',
            desiredSize: new go.Size(40, 40),
            source: unitOperationsConfig.upgrader.diagramIcon,
          })
        ), // end Auto Panel body
        this.$(
          go.Panel,
          'Vertical',
          {
            row: 1,
            column: 1,
          },
          this.gojsDiagramUtils.createInletPort({
            alignment: new go.Spot(0, 0.25),
            toMaxLinks: 1,
            portId: 'inlet1',
            margin: new go.Margin(3, 0),
            toolTip: 'Inlet 1',
          }),
          this.gojsDiagramUtils.createInletPort({
            alignment: new go.Spot(0, 0.75),
            toMaxLinks: 1,
            portId: 'inlet2',
            margin: new go.Margin(3, 0),
            toolTip: 'Inlet 2',
          })
        ),
        this.$(go.Panel, 'Vertical', new go.Binding('itemArray', 'outletPorts'), {
          row: 1,
          column: 3,
          itemTemplate: this.$(
            go.Panel,
            {
              _side: 'right',
              fromSpot: go.Spot.Right,
              toSpot: go.Spot.Right,
              fromLinkable: true,
              toLinkable: true,
              cursor: 'pointer',
            },
            new go.Binding('portId', 'portId'),
            this.$(
              go.Shape,
              'Rectangle',
              {
                fill: '#4972b0',
                stroke: 'Transparent',
                desiredSize: new go.Size(6, 6),
                margin: new go.Margin(3, 0),
              },
              {
                toolTip: this.$(
                  go.Adornment,
                  'Auto',
                  this.$(go.Shape, {
                    fill: '#CEF6F5',
                    stroke: 'transparent',
                  }),
                  this.$(
                    go.TextBlock,
                    {
                      margin: 4,
                    },
                    new go.Binding('text', 'tooltip')
                  )
                ),
              }
            )
          ), // end itemTemplate
        }) // end Vertical Panel
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      {
        toolTip: this.createConstraintTooltip(),
      }
    ); // end Node
  }

  getFlexibleUpgraderTemplate() {
    return this.$(
      go.Node,
      'Vertical',
      {
        locationObjectName: 'BODY',
        locationSpot: go.Spot.Center,
        // selectionObjectName: 'BODY',
      },
      // the body
      this.$(
        go.Panel,
        'Horizontal',
        {
          name: 'name',
        },
        this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' })
      ),
      this.$(
        go.Panel,
        'Table',
        this.$(
          go.Panel,
          'Auto',
          {
            row: 1,
            column: 2,
            name: 'BODY',
            stretch: go.GraphObject.Fill,
          },
          this.$(go.Shape, 'Rectangle', {
            fill: '#fff',
            stroke: null,
            strokeWidth: 0,
            minSize: new go.Size(40, 40),
          }),
          this.$(go.Picture, {
            name: 'ICON',
            desiredSize: new go.Size(40, 40),
            source: unitOperationsConfig.upgrader.diagramIcon,
          })
        ), // end Auto Panel body
        this.$(go.Panel, 'Vertical', new go.Binding('itemArray', 'inletPorts'), {
          row: 1,
          column: 0,
          itemTemplate: this.$(
            go.Panel,
            {
              _side: 'left',
              toSpot: go.Spot.Left,
              toLinkable: true,
              toMaxLinks: 1,
              cursor: 'pointer',
            },
            new go.Binding('portId', 'portId'),
            this.$(
              go.Shape,
              'Rectangle',
              {
                fill: '#4972b0',
                stroke: 'Transparent',
                desiredSize: new go.Size(6, 6),
                margin: new go.Margin(3, 0),
              },
              {
                toolTip: this.$(
                  go.Adornment,
                  'Auto',
                  this.$(go.Shape, {
                    fill: '#CEF6F5',
                    stroke: 'transparent',
                  }),
                  this.$(
                    go.TextBlock,
                    {
                      margin: 4,
                    },
                    new go.Binding('text', 'tooltip')
                  )
                ),
              }
            )
          ), // end itemTemplate
        }), // end Vertical Panel
        this.$(go.Panel, 'Vertical', new go.Binding('itemArray', 'outletPorts'), {
          row: 1,
          column: 3,
          itemTemplate: this.$(
            go.Panel,
            {
              _side: 'right',
              fromSpot: go.Spot.Right,
              fromLinkable: true,
              fromMaxLinks: 1,
              cursor: 'pointer',
            },
            new go.Binding('portId', 'portId'),
            this.$(
              go.Shape,
              'Rectangle',
              {
                fill: '#4972b0',
                stroke: 'Transparent',
                desiredSize: new go.Size(6, 6),
                margin: new go.Margin(3, 0),
              },
              {
                toolTip: this.$(
                  go.Adornment,
                  'Auto',
                  this.$(go.Shape, {
                    fill: '#CEF6F5',
                    stroke: 'transparent',
                  }),
                  this.$(
                    go.TextBlock,
                    {
                      margin: 4,
                    },
                    new go.Binding('text', 'tooltip')
                  )
                ),
              }
            )
          ), // end itemTemplate
        }) // end Vertical Panel
      ),
      this.$(go.Panel, 'Spot', this.gojsDiagramUtils.getBottomCenterReadinessIndicator(0)),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      {
        toolTip: this.createConstraintTooltip(),
      }
    ); // end Node
  }

  getFuelGasUtilityTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.$(go.Panel, 'Horizontal', this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' })),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              source: unitOperationsConfig.fuelGasUtilityUnitOperation.diagramIcon,
            })
          )
        )
      ),

      this.$(
        go.Panel,
        go.Panel.Position,
        this.$(
          go.TextBlock,
          {
            text: '\uf058 ',
            font: '16pt FontAwesome',
            stroke: '#2196F3',
            position: new go.Point(-100, 25),
          },
          new go.Binding('visible', '', fg => {
            return !!fg.isSolved;
          })
        ),
        {
          toolTip: this.$(
            go.Adornment,
            'Auto',
            this.$(go.Shape, {
              stroke: 'transparent',
              fill: '#CEF6F5',
            }),
            this.$(go.TextBlock, { margin: 4, text: 'Solved successfully' })
          ),
        }
      ),
      this.$(
        go.Panel,
        go.Panel.Position,
        this.$(
          go.TextBlock,
          {
            text: '\uf06a ',
            font: '16pt FontAwesome',
            stroke: 'red',
            position: new go.Point(-100, 25),
          },
          new go.Binding('visible', '', fg => {
            return !fg.isSolved;
          })
        ),
        {
          toolTip: this.$(
            go.Adornment,
            'Auto',
            this.$(go.Shape, {
              stroke: 'transparent',
              fill: '#CEF6F5',
            }),
            this.$(go.TextBlock, { margin: 4, text: 'Not solved' })
          ),
        }
      ),

      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers()
    );
  }

  getWaterUtilityTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.$(go.Panel, 'Horizontal', this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' })),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              source: unitOperationsConfig.waterUtilityUnitOperation.diagramIcon,
            })
          )
        )
      ),

      this.$(
        go.Panel,
        go.Panel.Position,
        this.$(
          go.TextBlock,
          {
            text: '\uf058 ',
            font: '16pt FontAwesome',
            stroke: '#2196F3',
            position: new go.Point(-100, 25),
          },
          new go.Binding('visible', '', wu => {
            return !!wu.isSolved;
          })
        ),
        {
          toolTip: this.$(
            go.Adornment,
            'Auto',
            this.$(go.Shape, {
              stroke: 'transparent',
              fill: '#CEF6F5',
            }),
            this.$(go.TextBlock, { margin: 4, text: 'Solved successfully' })
          ),
        }
      ),

      this.$(
        go.Panel,
        go.Panel.Position,
        this.$(
          go.TextBlock,
          {
            text: '\uf06a ',
            font: '16pt FontAwesome',
            stroke: 'red',
            position: new go.Point(-100, 25),
          },
          new go.Binding('visible', '', wu => {
            return !wu.isSolved;
          })
        ),
        {
          toolTip: this.$(
            go.Adornment,
            'Auto',
            this.$(go.Shape, {
              stroke: 'transparent',
              fill: '#CEF6F5',
            }),
            this.$(go.TextBlock, { margin: 4, text: 'Not solved' })
          ),
        }
      ),

      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers()
    );
  }

  getExtractionTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 0,
              source: unitOperationsConfig.extraction.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'out',
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getBottomCenterReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      {
        toolTip: this.createConstraintTooltip(),
      }
    );
  }

  getFlexibleExtractionTemplate() {
    return this.$(
      go.Node,
      'Vertical',
      {
        locationObjectName: 'BODY',
        locationSpot: go.Spot.Center,
        selectionObjectName: 'BODY',
      },
      // the body
      this.$(
        go.Panel,
        'Horizontal',
        {
          name: 'name',
        },
        this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' })
      ),
      this.$(
        go.Panel,
        'Table',
        this.$(
          go.Panel,
          'Auto',
          {
            row: 1,
            column: 2,
            name: 'BODY',
            stretch: go.GraphObject.Fill,
          },
          this.$(go.Shape, 'Rectangle', {
            fill: '#fff',
            stroke: null,
            strokeWidth: 0,
            minSize: new go.Size(40, 40),
          }),
          this.$(go.Picture, {
            name: 'ICON',
            desiredSize: new go.Size(40, 40),
            source: unitOperationsConfig.extraction.diagramIcon,
          })
        ), // end Auto Panel body
        this.$(go.Panel, 'Vertical', new go.Binding('itemArray', 'inletPorts'), {
          row: 1,
          column: 0,
          itemTemplate: this.$(
            go.Panel,
            {
              _side: 'left',
              fromSpot: go.Spot.Left,
              toSpot: go.Spot.Left,
              toLinkable: true,
              toMaxLinks: 1,
              cursor: 'pointer',
            },
            new go.Binding('portId', 'portId'),
            this.$(
              go.Shape,
              'Rectangle',
              {
                fill: '#4972b0',
                stroke: 'Transparent',
                desiredSize: new go.Size(6, 6),
                margin: new go.Margin(3, 0),
              },
              {
                toolTip: this.$(
                  go.Adornment,
                  'Auto',
                  this.$(go.Shape, {
                    fill: '#CEF6F5',
                    stroke: 'transparent',
                  }),
                  this.$(
                    go.TextBlock,
                    {
                      margin: 4,
                    },
                    new go.Binding('text', 'tooltip')
                  )
                ),
              }
            )
          ), // end itemTemplate
        }), // end Vertical Panel
        this.$(go.Panel, 'Vertical', new go.Binding('itemArray', 'outletPorts'), {
          row: 1,
          column: 3,
          itemTemplate: this.$(
            go.Panel,
            {
              _side: 'right',
              fromSpot: go.Spot.Right,
              toSpot: go.Spot.Right,
              fromLinkable: true,
              toLinkable: true,
              cursor: 'pointer',
            },
            new go.Binding('portId', 'portId'),
            this.$(
              go.Shape,
              'Rectangle',
              {
                fill: '#4972b0',
                stroke: 'Transparent',
                desiredSize: new go.Size(6, 6),
                margin: new go.Margin(3, 0),
              },
              {
                toolTip: this.$(
                  go.Adornment,
                  'Auto',
                  this.$(go.Shape, {
                    fill: '#CEF6F5',
                    stroke: 'transparent',
                  }),
                  this.$(
                    go.TextBlock,
                    {
                      margin: 4,
                    },
                    new go.Binding('text', 'tooltip')
                  )
                ),
              }
            )
          ), // end itemTemplate
        }) // end Vertical Panel
      ),
      this.gojsDiagramUtils.getBottomCenterReadinessIndicator(),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      {
        toolTip: this.createConstraintTooltip(),
      }
    ); // end Node
  }

  // TODO this seems to be obsolete --- remove
  getLevel2UpgraderTemplate() {
    return this.$(
      go.Node,
      'Vertical',
      {
        locationObjectName: 'BODY',
        locationSpot: go.Spot.Center,
        selectionObjectName: 'BODY',
      },
      // the body
      this.$(
        go.Panel,
        'Horizontal',
        {
          name: 'name',
        },
        this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' })
      ),
      this.$(
        go.Panel,
        'Table',
        this.$(
          go.Panel,
          'Auto',
          {
            row: 1,
            column: 2,
            name: 'BODY',
            stretch: go.GraphObject.Fill,
          },
          this.$(go.Shape, 'Rectangle', {
            fill: '#fff',
            stroke: null,
            strokeWidth: 0,
            minSize: new go.Size(40, 40),
          }),
          this.$(go.Picture, {
            name: 'ICON',
            desiredSize: new go.Size(40, 40),
            source: unitOperationsConfig.upgrader.diagramIcon,
          })
        ), // end Auto Panel body
        this.$(
          go.Panel,
          'Vertical',
          {
            row: 1,
            column: 1,
          },
          this.gojsDiagramUtils.createInletPort({
            alignment: new go.Spot(0, 0.5),
            toMaxLinks: 1,
            portId: 'inlet1',
            toolTip: 'In',
          })
        ),
        this.$(go.Panel, 'Vertical', new go.Binding('itemArray', 'outletPorts'), {
          row: 1,
          column: 3,
          itemTemplate: this.$(
            go.Panel,
            {
              _side: 'right',
              fromSpot: go.Spot.Right,
              toSpot: go.Spot.Right,
              fromLinkable: true,
              toLinkable: true,
              cursor: 'pointer',
            },
            new go.Binding('portId', 'portId'),
            this.$(
              go.Shape,
              'Rectangle',
              {
                fill: '#4972b0',
                stroke: 'Transparent',
                desiredSize: new go.Size(6, 6),
                margin: new go.Margin(3, 0),
              },
              {
                toolTip: this.$(
                  go.Adornment,
                  'Auto',
                  this.$(go.Shape, {
                    fill: '#CEF6F5',
                    stroke: 'transparent',
                  }),
                  this.$(
                    go.TextBlock,
                    {
                      margin: 4,
                    },
                    new go.Binding('text', 'tooltip')
                  )
                ),
              }
            )
          ), // end itemTemplate
        }) // end Vertical Panel
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      {
        toolTip: this.createConstraintTooltip(),
      }
    ); // end Node
  }

  getHydrotreaterTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
        // dragComputation: AvoidOverlapHelper.avoidNodeOverlap,
        linkConnected: (node, link, port) => {
          if (port.portId === 'in' && node.findLinksInto().count > 2) {
            const icon = node.findObject('ICON');
            const inlet = node.findObject('IN');

            const proportion = (icon.height + 5) / icon.height;
            icon.width *= proportion;
            icon.height *= proportion;

            inlet.width *= proportion;
            inlet.height *= proportion;
          }
        },

        linkDisconnected: (node, link, port) => {
          if (port.portId === 'in' && node.findLinksInto().count >= 2) {
            const icon = node.findObject('ICON');
            const inlet = node.findObject('IN');

            const proportion = (icon.height - 5) / icon.height;
            icon.width *= proportion;
            icon.height *= proportion;

            inlet.width *= proportion;
            inlet.height *= proportion;
          }
        },
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              source: unitOperationsConfig.hydrotreater.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              portId: 'in',
              name: 'IN',
              width: 4,
              height: 39,
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'out',
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getBottomCenterReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getHgoHydrotreaterTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.hgoHydrotreater.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.25),
              fromMaxLinks: 1,
              portId: 'naphtha',
              toolTip: 'Naphtha',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'lgoOut',
              toolTip: 'LGO',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.75),
              fromMaxLinks: 1,
              portId: 'hgoOut',
              toolTip: 'HGO',
            }),
            this.gojsDiagramUtils.getBottomCenterReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getLgoHydrotreaterTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.lgoHydrotreater.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.25),
              fromMaxLinks: 1,
              portId: 'naphtha',
              toolTip: 'Naphtha',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.75),
              fromMaxLinks: 1,
              portId: 'lgoOut',
              toolTip: 'LGO',
            }),
            this.gojsDiagramUtils.getBottomCenterReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getSeparatedHydrotreaterTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
        linkConnected: (node, link, port) => {
          if (port.portId === 'in' && node.findLinksInto().count > 2) {
            const icon = node.findObject('ICON');
            const inlet = node.findObject('IN');

            const proportion = (icon.height + 5) / icon.height;
            icon.width *= proportion;
            icon.height *= proportion;

            inlet.width *= proportion;
            inlet.height *= proportion;
          }
        },

        linkDisconnected: (node, link, port) => {
          if (port.portId === 'in' && node.findLinksInto().count >= 2) {
            const icon = node.findObject('ICON');
            const inlet = node.findObject('IN');

            const proportion = (icon.height - 5) / icon.height;
            icon.width *= proportion;
            icon.height *= proportion;

            inlet.width *= proportion;
            inlet.height *= proportion;
          }
        },
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              source: unitOperationsConfig.hydrotreater.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              portId: 'in',
              name: 'IN',
              width: 4,
              height: 39,
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.25),
              fromMaxLinks: 1,
              portId: 'lightEnds',
              toolTip: 'Light Ends',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.75),
              fromMaxLinks: 1,
              portId: 'hydrotreatedProduct',
              toolTip: 'Hydrotreated Product',
            }),
            this.gojsDiagramUtils.getBottomCenterReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getDCUTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.dcu.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'in',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(0.5, 0),
              fromMaxLinks: 1,
              portId: 'lightEnds',
              toolTip: 'Light Ends',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.25),
              fromMaxLinks: 1,
              portId: 'lightNaphtha',
              toolTip: 'Light Naphtha',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'heavyNaphtha',
              toolTip: 'Heavy Naphtha',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.75),
              fromMaxLinks: 1,
              portId: 'kero',
              toolTip: 'Kero',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(0.5, 1),
              fromMaxLinks: 1,
              portId: 'gasOil',
              toolTip: 'Gas Oil',
            }),
            this.gojsDiagramUtils.getAllSidesBusyReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getFluidCokerTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.fluidCoker.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.25),
              toMaxLinks: 1,
              portId: 'atb',
              toolTip: 'ATB',
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              toMaxLinks: 1,
              portId: 'vtb',
              toolTip: 'VTB',
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.75),
              toMaxLinks: 1,
              portId: 'residue',
              toolTip: 'Residue',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(0.5, 0),
              fromMaxLinks: 1,
              portId: 'butane',
              toolTip: 'Butane',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.33),
              fromMaxLinks: 1,
              portId: 'naphtha',
              toolTip: 'Naphtha',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.66),
              fromMaxLinks: 1,
              portId: 'lgo',
              toolTip: 'LGO',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(0.5, 1),
              fromMaxLinks: 1,
              portId: 'hgo',
              toolTip: 'HGO',
            }),
            this.gojsDiagramUtils.getAllSidesBusyReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getHydrogenPlantTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.hydrogenPlant.diagramIcon,
            }),
            this.gojsDiagramUtils.getCenterRightReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getSulfurPlantTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.sulfurPlant.diagramIcon,
            }),
            this.gojsDiagramUtils.getCenterRightReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getHydrocrackerTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.hydrocracker.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'in',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(0.5, 0),
              fromMaxLinks: 1,
              portId: 'diluent',
              fromSpot: go.Spot.Top,
              toolTip: 'Diluent',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.25),
              fromMaxLinks: 1,
              portId: 'naphtha',
              toolTip: 'Naphtha',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'lgo',
              toolTip: 'LGO',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(0.5, 1),
              fromMaxLinks: 1,
              portId: 'residue',
              toolTip: 'Residue',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.75),
              fromMaxLinks: 1,
              fromSpot: go.Spot.Bottom,
              portId: 'hgo',
              toolTip: 'HGO',
            }),
            this.gojsDiagramUtils.getAllSidesBusyReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getInletTransferUnitTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
        deletable: true,
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Horizontal',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'right', marginTop: 6 }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.inletTransferUnit.diagramIcon,
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'out',
              toolTip: 'Out',
            }),
            this.$(
              go.Panel,
              'Vertical',
              { alignment: new go.Spot(0, 0.7), width: 20, height: 20 },
              this.$(go.Shape, { figure: 'Rectangle', width: 12, height: 2, stroke: '#666', fill: '#666' })
            ),
            this.gojsDiagramUtils.getTopRightReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getOutletTransferUnitTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
        deletable: true,
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Horizontal',
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.outletTransferUnit.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'In',
            }),
            this.$(
              go.Panel,
              'Vertical',
              { alignment: new go.Spot(1, 0.7), width: 20, height: 20 },
              this.$(go.Shape, { figure: 'Rectangle', width: 12, height: 2, stroke: '#666', fill: '#666' })
            ),
            this.gojsDiagramUtils.getTopLeftReadinessIndicator()
          ),
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'left', marginTop: 6 })
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getLightEndsRecoveryUnitTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.lgoHydrotreater.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.25),
              fromMaxLinks: 1,
              portId: 'gas',
              toolTip: 'Gas',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.75),
              fromMaxLinks: 1,
              portId: 'butanes',
              toolTip: 'Butanes',
            }),
            this.gojsDiagramUtils.getBottomCenterReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }
  // endregion
}
