import { BaseObject } from '../base-object';
import { Case } from '../case';
import { SimulationVariable } from '../simulation-variable';
import { AssayManagerFluidAnalysisResourceData } from './assay-manager-resource-data';
import { FluidAnalysis } from './fluid-analysis';
import { ReadyStatus } from '../../_config/ready-status.enum';
import { BoundedObject } from '../bounded-object';
import { Quantity } from '../../_config/quantity.enum';
import { getGasCompositionCompoundNames } from '../../_config/gas-composition-compounds';

export class GasFluidAnalysis extends FluidAnalysis implements BoundedObject {
  category = 'gas-fluid-analysis';
  ownerBaseObject: BaseObject;
  private readonly numberOfCompounds = 18;

  constructor(gasFluidAnalysis: any, ownerCase: Case, ownerBaseObject?: BaseObject) {
    super(gasFluidAnalysis, ownerCase);
    if (gasFluidAnalysis.composition) {
      if (gasFluidAnalysis.composition.length < this.numberOfCompounds) {
        gasFluidAnalysis.composition = [];
      }
    }

    this.ownerBaseObject = ownerBaseObject;
    this.assayName = gasFluidAnalysis.assayName || '';
    this.assayType = gasFluidAnalysis.assayType || '';
    this.composition = [];

    this.initSimVars();

    this.initComposition(gasFluidAnalysis.composition || []);

    this.setQuantities();

    this.upperBound = gasFluidAnalysis.upperBound;
    this.lowerBound = gasFluidAnalysis.lowerBound;
    this.readyStatus = gasFluidAnalysis.readyStatus || ReadyStatus.UNDEFINED;
  }

  override addSimVarsToPool() {
    super.addSimVarsToPool();
    for (const c of this.composition) {
      this.ownerCase.addToPools(c);
    }
  }

  override overwriteValues(another: BaseObject) {
    super.overwriteValues(another);

    const fg = another as GasFluidAnalysis;

    for (let i = 0; i < this.composition.length; i++) {
      this.composition[i].overwriteValues(fg.composition[i]);
    }
  }

  override convertToInternalUnit() {
    super.convertToInternalUnit();
  }

  protected override ignoreForOverWrite(propertyName: string): boolean {
    return super.ignoreForOverWrite(propertyName) || this[propertyName] instanceof Array;
  }

  override getName() {
    return this.ownerBaseObject.getName();
  }

  setSimulationVariableNames(): void {}

  override setSimulationVariablesOwner(): void {
    super.setSimulationVariablesOwner();
    for (let i = 0; i < this.composition.length; i++) {
      this.composition[i].setSimulationVariablesOwner();
    }
  }

  initSimVars(): void {}

  setOwnerBaseObject(ownerBaseObject: BaseObject): void {
    this.ownerBaseObject = ownerBaseObject;
  }

  initComposition(composition) {
    for (let idx = 0; idx < this.numberOfCompounds; idx++) {
      if (composition[idx] instanceof SimulationVariable || typeof composition[idx] === 'undefined') {
        this.composition.push(new SimulationVariable(composition[idx], this.ownerCase, this));
      }
    }

    this.setCompositionNames();
  }

  getCompositionElement(name: string): SimulationVariable {
    return this.composition.find(sv => sv.name === name);
  }

  setCompositionNames() {
    const names = getGasCompositionCompoundNames();

    if (this.composition.length === names.length) {
      for (let idx = 0; idx < names.length; idx++) {
        this.composition[idx].setName(names[idx]);
      }
    }
  }

  setQuantities() {
    for (const sv of this.composition) {
      sv.setQuantity(Quantity.MOLE_FRACTION);
    }

    this.quantity = Quantity.MOLE_FRACTION;
  }

  override detectChanges(another: GasFluidAnalysis) {
    let changes = super.detectChanges(another);

    for (let idx = 0; idx < another.composition.length; idx++) {
      changes = changes || this.composition[idx].detectValueChange(another.composition[idx]);
    }
    return changes;
  }

  override getSimVars() {
    const simVars = super.getSimVars();
    for (const ce of this.composition) {
      simVars.push(ce);
    }

    return simVars;
  }

  protected override isPropertyBlackListed(property: any): boolean {
    return property instanceof Case || typeof property === 'function' || property === this.ownerBaseObject;
  }

  override getChildrenObjectsForRemoval(): BaseObject[] {
    return super.getChildrenObjectsForRemoval();
  }

  loadResourceData(resourceData: AssayManagerFluidAnalysisResourceData) {
    this.upperBound = resourceData.composition.upperBound;
    this.lowerBound = resourceData.composition.lowerBound;

    for (const sv of this.composition) {
      sv.upperBound = resourceData.composition.upperBound;
      sv.lowerBound = resourceData.composition.lowerBound;
    }
  }

  public toJSON(): any {
    const objectToSerialize: any = {};
    let val: any;
    for (val in this) {
      if (this[val] instanceof SimulationVariable) {
        objectToSerialize[val] = (<SimulationVariable>this[val]).id;
      } else if (this[val] === this.composition) {
        objectToSerialize.composition = [];
        for (const c of this.composition) {
          objectToSerialize.composition.push(c.id);
        }
      } else if (this.isPropertyBlackListed(this[val])) {
        // bruh
      } else {
        objectToSerialize[val] = this[val];
      }
    }

    return objectToSerialize;
  }

  override dePersist(gfa: any) {
    if (gfa.composition.length === this.numberOfCompounds) {
      for (let idx = 0; idx < this.numberOfCompounds; idx++) {
        this.composition.push(this.ownerCase.getSimulationVariable(gfa.composition[idx]));
      }
      this.setCompositionNames();
    }
  }
}
