import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FuelGasSource } from '../../../_models/_unit-operations/fuel-gas/fuel-gas-source';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';
import { CoreService } from '../../../_services/core.service';
import { GasFluidAnalysis } from '../../../_models/_fluid/gas-fluid-analysis';
import { FlowsheetService } from '../../../_services/flowsheet.service';
import { VariableStatus } from '../../../_config/variable-status.enum';

@Component({
  selector: 'sob-fuel-gas-source',
  templateUrl: './fuel-gas-source.component.html',
  styleUrls: ['./fuel-gas-source.component.css'],
})
export class FuelGasSourceComponent implements OnInit {
  @Input() unitOperation: FuelGasSource;
  @Input() formGroupWrapper: BaseObjectFormGroupWrapper;
  currentAnalysis: GasFluidAnalysis;

  constructor(
    private fb: UntypedFormBuilder,
    private svfb: SimVarFormBuilder,
    public coreService: CoreService,
    private flowsheetService: FlowsheetService
  ) {}

  ngOnInit(): void {
    this.addControls();
    this.updateCurrentAnalysis();
    this.formGroupWrapper.storeOriginalValue();
  }

  addControls() {
    this.propertyWindowFormGroup.addControl('name', this.fb.control(this.unitOperation.name));
    this.propertyWindowFormGroup.addControl('flowrate', this.svfb.control(this.unitOperation.flowrate));
    this.propertyWindowFormGroup.addControl('isOptimizable', this.fb.control(this.unitOperation.isOptimizable));
    this.propertyWindowFormGroup.addControl('fluidAnalysisId', this.fb.control(this.unitOperation.fluidAnalysisId));
    this.isOptimizableChanged(this.unitOperation.isOptimizable);
  }

  get propertyWindowFormGroup() {
    return this.formGroupWrapper.getFormGroup();
  }

  get fluidAnalyses() {
    return this.coreService.currentCase.assayManager.getAssaysByCategory('Gas');
  }

  updateCurrentAnalysis(id?) {
    if (id) {
      this.unitOperation.fluidAnalysisId = id;
    }
    if (this.unitOperation.fluidAnalysisId) {
      this.currentAnalysis = this.coreService.currentCase.assayManager.findFluidAnalysisById(
        this.unitOperation.fluidAnalysisId
      );
      this.propertyWindowFormGroup.get('fluidAnalysisId').setValue(this.unitOperation.fluidAnalysisId);
      this.assayChanged();
      this.formGroupWrapper.storeOriginalValue();
    }
  }

  assayChanged(): void {
    $(`#compositionUnit${this.unitOperation.id}`).val(this.currentAnalysis.composition[0].unit);
    let total = 0;
    for (let i = 0; i < this.currentAnalysis.composition.length; i++) {
      const simVar = this.currentAnalysis.composition[i];
      total += simVar.convertToCaptureUnit(true);
      $(`#composition${i}`).val(simVar.convertToCaptureUnit(true));
    }
    $('#compositionTotalUO').val(total);
  }

  openAssayManager(id: string): void {
    this.flowsheetService.closePropertyWindow();
    $('#assayManagerModal').modal('show');
    const editButton: HTMLElement = document.getElementById(`editAssayButton${id}`) as HTMLElement;
    editButton.click();
  }

  isOptimizableChanged(checked: boolean) {
    if (checked) {
      this.propertyWindowFormGroup.get('flowrate').get('value').disable();
    } else {
      if (this.unitOperation.flowrate.variableStatus === VariableStatus.SOLVER_CALCULATED) {
        this.unitOperation.flowrate.variableStatus = VariableStatus.USER_SPECIFIED;
      }
      this.propertyWindowFormGroup.get('flowrate').get('value').enable();
    }
  }
}
