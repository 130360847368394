<div>
  <label class="subtitle-v2 gas-label">Gas</label>

  <a class="btn btn-outline btn-success btn-xs m-l-sm" href="#gasDiv" (click)="switchOpened()" aria-expanded="false">
    <i *ngIf="!isOpened" class="fa fa-plus"></i>
    <i *ngIf="isOpened" class="fa fa-minus"></i>
  </a>
</div>

<div class="collapse" id="gasDiv" aria-expanded="false">
  <div class="form-group">
    <label for="customGasModelEnabled" class="control-label col-sm-4"> Use Custom Model for Gas </label>
    <div class="col-sm-4">
      <label class="control-label">
        <input type="checkbox" id="customGasModelEnabled" [(ngModel)]="unitOperation.useCustomGasModel" />
      </label>
    </div>

    <div class="clearfix"></div>
  </div>
  <div *ngIf="unitOperation.useCustomGasModel">
    <sob-open-code-editor-button
      [code]="unitOperation.gasModelScript"
      [defaultCodeProvider]="defaultCodeProvider"
      [instanceId]="'gasCodeEditor'"
      [disabled]="!unitOperation.useCustomGasModel"
      (codeSaved)="saveGasCustomModel($event)"></sob-open-code-editor-button>
  </div>
  <hr class="m-t-md m-b-md" />
  <div class="form-group">
    <label [attr.for]="'fuelGasHeatingValue'" class="pw-label control-label col-sm-4">
      Fuel Gas Heating Value (LHV)
    </label>
    <sim-var-input [inputId]="'fuelGasHeatingValue'" [simVar]="unitOperation.fuelGasHeatingValue"> </sim-var-input>
  </div>
  <div class="form-group">
    <label [attr.for]="'fuelGasEmissionFactor'" class="pw-label control-label col-sm-4">
      Fuel Gas Emission Factor
    </label>
    <sim-var-input [inputId]="'fuelGasEmissionFactor'" [simVar]="unitOperation.fuelGasEmissionFactor"> </sim-var-input>
  </div>

  <div class="form-group">
    <label [attr.for]="'energyFlow'" class="pw-label control-label col-sm-4"> Energy Flow </label>
    <sim-var-input [inputId]="'energyFlow'" [readOnly]="true" [simVar]="unitOperation.energyFlow"> </sim-var-input>
  </div>

  <div class="form-group">
    <label [attr.for]="'flowrate'" class="pw-label control-label col-sm-4"> Fuel Gas Flowrate </label>
    <sim-var-input [inputId]="'flowrate'" [readOnly]="true" [simVar]="unitOperation.flowrate"></sim-var-input>
  </div>

  <div class="form-group">
    <label class="pw-label control-label col-sm-4"> Gas Analysis </label>
    <div class="col-sm-8">
      <sob-assay-selector
        [fluidAnalyses]="fluidAnalyses"
        [currentAnalysis]="currentAnalysis"
        (updateCurrentAssay)="updateCurrentAnalysis($event)">
      </sob-assay-selector>
    </div>
  </div>

  <ng-container *ngIf="unitOperation.useCustomGasModel">
    <div class="form-group">
      <label for="primaryGasPredictionMethodEnabled" class="control-label col-sm-4">
        Primary Gas Prediction Method
      </label>
      <div class="col-sm-4">
        <label class="control-label">
          <input
            type="checkbox"
            id="primaryGasPredictionMethodEnabled"
            [(ngModel)]="unitOperation.usePrimaryGasPredictionMethod" />
        </label>
      </div>
    </div>
  </ng-container>
</div>
