import { SimulationVariable } from './simulation-variable';
import { Case } from './case';

export abstract class BaseObject {
  id: string;
  name: string;
  abstract category: string;
  ownerCase: Case;

  /**
   *
   */
  constructor(id: string, ownerCase: Case) {
    this.id = id || (<any>window).uuid();
    this.ownerCase = ownerCase || null;
  }

  setName(name: string) {
    this.name = name;
  }

  getName() {
    return this.name;
  }

  updateStatus(another: BaseObject): void {
    this.updateSimulationVariablesStatus(another);
  }

  updateSimulationVariablesStatus(another: BaseObject): void {
    let simVar: any;

    // eslint-disable-next-line guard-for-in
    for (simVar in this) {
      const attr = this[simVar];

      if (attr instanceof SimulationVariable) {
        const otherSimVar = another[simVar];
        (<SimulationVariable>attr).updateVariableStatus(<SimulationVariable>otherSimVar);
      }
    }
  }

  /**
   * Converts all simulation variables to its internal value
   */
  convertToInternalUnit(): void {
    // loop abusing? where?
    const simVars = this.getSimVars();
    for (const simVar of simVars) {
      simVar.value = simVar.convertToInternalUnit();
    }
  }

  setSimulationVariablesOwner(): void {
    for (const sv of this.getSimVars()) {
      sv.setOwnerBaseObject(this);
    }
  }

  getAllBaseObjects(): Array<BaseObject> {
    const allBaseObjects: Array<BaseObject> = [];
    let val: any;
    for (val in this) {
      if (this[val] instanceof BaseObject) {
        allBaseObjects.push(this[val]);
      }
    }
    return allBaseObjects;
  }

  getChildrenObjectsForRemoval(): BaseObject[] {
    return this.getSimVars();
  }

  getSimVars(): Array<SimulationVariable> {
    const simVars: Array<SimulationVariable> = [];
    let simVar: any;
    for (simVar in this) {
      if (this[simVar] instanceof SimulationVariable) {
        simVars.push(<SimulationVariable>this[simVar]);
      }
    }
    return simVars;
  }

  getSimVarIds(): Array<String> {
    const ids: Array<String> = [];
    let simVar: any;
    for (simVar in this) {
      if (this[simVar] instanceof SimulationVariable) {
        ids.push(this[simVar].id);
      }
    }
    return ids;
  }

  /**
   * This method should be used ONLY for deserialization, other usages of this are DANGEROUS
   * TODO: Rename to Dangerous!
   * @param id
   */
  getSimulationVariableSafe(id: string): SimulationVariable {
    let sv = this.ownerCase.getSimulationVariable(id);

    if (sv) {
      return sv;
    }

    sv = new SimulationVariable({}, this.ownerCase, this);
    this.ownerCase.addToPools(sv);

    // eslint-disable-next-line no-console
    // console.warn(
    //   eslint-disable-next-line max-len
    // `Warning Simulation Variable with the id ${id} was not found in the pools - A new one has been created and added to the pools Owner Base Object Data: ${this.category} - ${this.name} - ${this.id}`
    // );
    return sv;
  }

  addSimVarsToPool(): void {
    const simVars = this.getSimVars();
    for (const sv of simVars) {
      this.ownerCase.addToPools(sv);
    }
  }

  overwriteValues(another: BaseObject): void {
    // eslint-disable-next-line guard-for-in
    for (const val in another) {
      const currentValue = another[val];

      if (currentValue instanceof SimulationVariable) {
        if (!this.ignoreForOverWrite(val)) {
          (<SimulationVariable>this[val]).overwriteValues(<SimulationVariable>currentValue);
        }
      } else if (this.ignoreForOverWrite(val)) {
        // nothing ...
      } else {
        this[val] = another[val];
      }
    }
  }

  detectChanges(another: BaseObject): boolean {
    // eslint-disable-next-line guard-for-in
    for (const val in another) {
      const currentValue = another[val];

      if (currentValue instanceof SimulationVariable) {
        if ((<SimulationVariable>this[val]).detectValueChange(<SimulationVariable>currentValue)) {
          return true;
        }
      }
    }

    return false;
  }

  // this name is... what about 'plain'
  public toNonCircularObject(): Object {
    // this will be useful for saving to gojs and for creating a gojs model using
    // a flowsheet as the source
    const plainObject = {};
    let val: any;
    for (val in this as Object) {
      if (this[val] instanceof SimulationVariable) {
        plainObject[val] = (<SimulationVariable>this[val]).toJSON();
      } else if (this.isPropertyBlackListed(this[val])) {
        // welp, nothing...
      } else {
        plainObject[val] = this[val];
      }
    }

    return plainObject;
  }

  protected isPropertyBlackListed(property: any): boolean {
    return property instanceof Case || typeof property === 'function';
  }

  protected ignoreForOverWrite(propertyName: string): boolean {
    return typeof this[propertyName] === 'function';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public dePersist(persistedObj: any) {
    // nothing
  }
}
