import { UnitOperation } from '../../unit-operation';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { Case } from '../../case';
import { GasExportDestination } from '../../../_config/unit-operations/gas-export-destination.enum';

export class GasExport extends UnitOperation {
  category = unitOperationsConfig.gasExport.key;
  destination: string;

  constructor(unitOperation: any | GasExport, ownerCase: Case) {
    super(unitOperation, ownerCase);

    this.initValues(unitOperation);
  }

  initValues(unitOperation: any) {
    this.destination = unitOperation.destination || GasExportDestination.SINK;
  }

  resetDestinationValue() {
    this.destination = GasExportDestination.SINK;
  }

  setSimulationVariableNames() {}
}
