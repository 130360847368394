<ng-container [formGroup]="informationStreamFormGroup">
  <div class="form-group" formGroupName="flowrate">
    <label class="control-label col-sm-4"> Flowrate </label>

    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="informationStream.flowrate.quantity">
          </sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="informationStream.flowrate">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4"> Gas Analysis </label>

    <div class="col-sm-8">
      <div class="row">
        <div class="col-xs-12">
          <input type="text" class="form-control" formControlName="gasAnalysis" />
        </div>
      </div>
    </div>
  </div>
</ng-container>
