import { Subject } from 'rxjs';
import { SimulationVariable } from './simulation-variable'; // DO NOT MOVE!
import { UnitOperationFactory } from './_unit-operations/unit-operation-factory';
import { UnitOperation } from './unit-operation';
import { BaseObject } from './base-object';
import { SuncorMaterialStream } from './_streams/suncor-material-stream';
import { CaseSettings } from './case-settings';

import { CaseStudyManager } from './_case-study/case-study-manager';
import { CaseStudyCategory } from './_case-study/case-study-category';
import { unitOperationsConfig } from '../_config/unit-operations.config';
import { ObjectiveFunction } from './_optimization/objective-function';
import { SuncorCalculator } from './suncor-calculator';
import { Flowsheet } from './flowsheet-manager/flowsheet';
import { AssayManager } from './_fluid/assay-manager';
import { DRU } from './_unit-operations/dru';
import { VAC } from './_unit-operations/vac';
import { CommodityTank } from './_unit-operations/commodity-tank';
import { Hydrotreater } from './_unit-operations/hydrotreater';
import { ConstraintViolationEntry } from './constraint-violation-entry';
import { MarginalValueEntry } from './marginal-value-entry';
import { BaseStream } from './_streams/base-stream';
import { WaterMaterialStream } from './_streams/water-material-stream';
import { SteamUseUnitOperation } from './_unit-operations/steam-use-unit-operation';
import { BaseWaterImportUnitOperation } from './_unit-operations/utilties/base-water-import-unit-operation';
import { GasContributorUnitOperation } from './_unit-operations/gas-contributor-unit-operation';
import { FuelGasSourceImport } from './_unit-operations/fuel-gas/fuel-gas-source-import';
import { FuelGasSinkImport } from './_unit-operations/fuel-gas/fuel-gas-sink-import';
import { FuelGasMaterialStream } from './_streams/fuel-gas-material-stream';
import { WaterPipeSegment } from './_unit-operations/utilties/water-pipe-segment';
import { MultiPeriodSettings } from './_multi-period/multi-period-settings';
import { BaseObjectComment } from './base-object-comment';
import { DiluentSource } from './_unit-operations/diluent-source';
import { Refinery } from './_unit-operations/refinery';
import { SagdSource } from './_unit-operations/sagd-source';
import { MineSource } from './_unit-operations/mine-source';
import { ThirdPartySource } from './_unit-operations/third-party-source';
import { OffshoreSource } from './_unit-operations/offshore-source';
import { Upgrader } from './_unit-operations/upgrader';
import { BitumenConversion } from './_unit-operations/bitumen-conversion';
import { ThirdPartyRefinery } from './_unit-operations/third-party-refinery';
import { Extraction } from './_unit-operations/extraction';
import { Pipe } from './_unit-operations/pipe';
import { DCU } from './_unit-operations/dcu';
import { FluidCoker } from './_unit-operations/fluid-coker';
import { Hydrocracker } from './_unit-operations/hydrocracker';
import { SulfurPlant } from './_unit-operations/sulfur-plant';
import { HydrogenPlant } from './_unit-operations/hydrogen-plant';
import { WaterBoiler } from './_unit-operations/utilties/water-boiler';
import { WaterSource } from './_unit-operations/utilties/water-source';
import { WaterHeatExchanger } from './_unit-operations/utilties/water-heat-exchanger';
import { WaterTurbine } from './_unit-operations/utilties/water-turbine';
import { WaterHeater } from './_unit-operations/utilties/water-heater';
import { WaterCooler } from './_unit-operations/utilties/water-cooler';
import { WaterTwoPhaseSeparator } from './_unit-operations/utilties/water-two-phase-separator';
import { WaterPump } from './_unit-operations/utilties/water-pump';
import { WaterPipe } from './_unit-operations/utilties/water-pipe';
import { WaterCogeneration } from './_unit-operations/utilties/water-cogeneration';
import { FuelGasSource } from './_unit-operations/fuel-gas/fuel-gas-source';
import { FuelGasAmineContactor } from './_unit-operations/fuel-gas/fuel-gas-amine-contactor';
import { GasExport } from './_unit-operations/upgrader/gas-export';

export class Case extends BaseObject {
  category = 'case';

  comments: any = { text: '' };

  isSolved: boolean;
  isReady: boolean;
  caseSettings: CaseSettings;
  // objectiveFunction: ObjectiveFunction;
  caseStudyManager: CaseStudyManager;
  // economicAnalyzer: EconomicAnalyzer;
  calculator: SuncorCalculator;
  objectiveFunction: ObjectiveFunction;
  assayManager: AssayManager;

  // I think we should have a map Type. or Hash type.
  // Or something that implements forEach, serialization be dammed
  simulationVariablePool: { [id: string]: SimulationVariable } = {};
  unitOperationPool: { [id: string]: UnitOperation } = {};
  materialStreamPool: { [id: string]: BaseStream } = {};
  otherBaseObjectPool: { [id: string]: BaseObject } = {}; // case settings.. and other stuff (?)
  commentsPool: BaseObjectComment[] = [];

  /**
   * This is the id of the main flowsheet and by no means it should change
   * @private
   */
  private flowsheetId: string;
  flowsheetPool: { [id: string]: Flowsheet } = {};

  constraintViolationEntries: ConstraintViolationEntry[] = [];
  marginalValueEntries: MarginalValueEntry[] = [];
  multiPeriodEnabled: boolean;
  multiPeriodSettings: MultiPeriodSettings;

  // fancy delegates
  private simulationVariableDeletedSubject = new Subject<SimulationVariable>();
  simulationVariableDeletedRequest = this.simulationVariableDeletedSubject.asObservable();

  // kpi manager triggers this subject and uncertainty analyses subscribe to it
  // should this be replaced by a service? YES
  kpiDeletedSubject = new Subject<SimulationVariable>();
  kpiDeletedRequest = this.kpiDeletedSubject.asObservable();

  // this is a temporary property that'll help remember either the user wants to wait for case results
  // or prefer to receive them via email.
  solveOption: string;

  /**
   * A string that holds the JSON model of the gojs diagram
   * ### The manipulation of this property is extremely dangerous!
   * In the code, it is used to store the JSON of the subflowsheets.
   *
   * This property is not serialized at case level, however, it is serialized as a separated file in the .opt zipped
   * file and the contents of this property are assumed to contain the main flowsheet diagram
   *
   * Based on the usage of this property when saving a file, it seems that this should always reference the
   * main flowsheet diagram.
   * ###
   *
   * ### This property seems to be redundant
   * The JSON strings that contain gojs models are stored (in memory) in the flowsheet pool...
   * Seems the same thing (saving the gojs JSON) is done twice - one here and other in the flowsheet pool.
   *
   * **Original comment (Humberto)** *temporarily stores the case's diagram to be loaded on case switch*
   * @private
   */
  private diagram: string;

  private activeFlowsheet: Flowsheet;

  constructor(c: any) {
    super(c.id, null);
    this.isSolved = !!c.isSolved;
    this.isReady = !!c.isReady;
    this.name = c.name;
    this.flowsheetId = c.flowsheetId;
    this.solveOption = c.solveOption || 'waitForResults';
    this.multiPeriodEnabled = !!c.multiPeriodEnabled;

    if (c.comments) {
      this.comments = c.comments; // wat
    }
  }

  // region base functions
  cloneUnitOperation(sourceUnitOp: UnitOperation) {
    return UnitOperationFactory.createUnitOperation(sourceUnitOp, this);
  }

  createAndAddToUnitOperations(sourceUnitOp: any, resourceData?): UnitOperation {
    const unitOperation = this.createUnitOperation(sourceUnitOp, resourceData);
    this.addToUnitOperations(unitOperation);
    return unitOperation;
  }

  createUnitOperation(sourceUnitOp: any, resourceData?): UnitOperation {
    return UnitOperationFactory.createUnitOperationAndSetDefaultValues(sourceUnitOp, this, resourceData);
  }

  addToUnitOperations(unitOperation: UnitOperation) {
    unitOperation.addSimVarsToPool();
    this.addToPools(unitOperation);
  }

  /**
   * Puts all unit operations from this flowsheet into an array given a category
   * @param category A string containing the desired
   * @returns {Array<UnitOperation>}
   */
  findUnitOpsByCategory(category: string): Array<any> {
    return this.filterUnitOperations((uo: UnitOperation): boolean => {
      return uo.category === category;
    });
  }

  findUnitOperationsByFlowsheetId(flowsheetId: string): UnitOperation[] {
    return this.filterUnitOperations(uo => uo.flowsheetId === flowsheetId);
  }

  /**
   *
   * @param callback A callback function that must return a boolean value,
   * if true, the current unit operation will be added to array result.
   * @returns {Array<UnitOperation>} An array containing unit operations that
   * satisfy the condition given by the callback function
   */
  filterUnitOperations(callback: (uo: UnitOperation) => boolean): Array<UnitOperation> {
    const result: Array<UnitOperation> = [];
    for (const uo in this.unitOperationPool) {
      if (callback(this.unitOperationPool[uo])) {
        result.push(this.unitOperationPool[uo]);
      }
    }

    return result;
  }

  getBitumenConversionUnits() {
    const bitumenUnits: BitumenConversion[] = [];
    for (const uo in this.unitOperationPool) {
      if (this.unitOperationPool[uo].category === unitOperationsConfig.bitumenConversion.key) {
        bitumenUnits.push(this.unitOperationPool[uo] as BitumenConversion);
      }
    }
    return bitumenUnits;
  }

  getGasExportUnitsByFlowsheetId(flowsheetId: string) {
    return this.filterUnitOperations(uo => {
      return uo.flowsheetId === flowsheetId && uo instanceof GasExport;
    });
  }

  filterAllMaterialStreams(callback: (s: BaseStream) => boolean): BaseStream[] {
    const result: BaseStream[] = [];
    // eslint-disable-next-line guard-for-in
    for (const sId in this.materialStreamPool) {
      const stream = this.getMaterialStream(sId);
      if (callback(stream)) {
        result.push(stream);
      }
    }

    return result;
  }

  filterWaterMaterialStreams(callback: (s: WaterMaterialStream) => boolean): Array<WaterMaterialStream> {
    const result: Array<WaterMaterialStream> = [];
    // eslint-disable-next-line guard-for-in
    for (const sId in this.materialStreamPool) {
      const stream = this.getWaterStream(sId);
      if (stream instanceof WaterMaterialStream) {
        if (callback(stream)) {
          result.push(stream);
        }
      }
    }

    return result;
  }

  filterFuelGasMaterialStreams(callback: (s: FuelGasMaterialStream) => boolean): Array<FuelGasMaterialStream> {
    const result: Array<FuelGasMaterialStream> = [];
    // eslint-disable-next-line guard-for-in
    for (const sId in this.materialStreamPool) {
      const stream = this.getFuelGasStream(sId);
      if (stream instanceof FuelGasMaterialStream) {
        if (callback(stream)) {
          result.push(stream);
        }
      }
    }

    return result;
  }

  filterSuncorMaterialStreams(callback: (s: SuncorMaterialStream) => boolean): Array<SuncorMaterialStream> {
    const result: Array<SuncorMaterialStream> = [];
    // eslint-disable-next-line guard-for-in
    for (const sId in this.materialStreamPool) {
      const stream = this.getSuncorMaterialStream(sId);
      if (stream instanceof SuncorMaterialStream) {
        if (callback(stream)) {
          result.push(stream);
        }
      }
    }

    return result;
  }

  filterSimvars(callback: (s: SimulationVariable) => boolean): Array<SimulationVariable> {
    const result: Array<SimulationVariable> = [];
    for (const s in this.simulationVariablePool) {
      if (callback(this.simulationVariablePool[s])) {
        result.push(this.simulationVariablePool[s]);
      }
    }

    return result;
  }

  filterFlowsheets(callback: (s: Flowsheet) => boolean): Array<Flowsheet> {
    const result: Array<Flowsheet> = [];
    for (const s in this.flowsheetPool) {
      if (callback(this.flowsheetPool[s])) {
        result.push(this.flowsheetPool[s]);
      }
    }

    return result;
  }

  getFirstLevelFlowsheet(): Flowsheet {
    return this.filterFlowsheets(f => !f.isSubflowsheet())[0];
  }

  getSimulationVariable(id: string): SimulationVariable {
    return this.simulationVariablePool[id];
  }

  getUnitOperation(id: string): UnitOperation {
    return this.unitOperationPool[id];
  }

  getUnitOperationName(id: string): string {
    return this.unitOperationPool[id].name;
  }

  getAllSuncorMaterialStreamIds(): string[] {
    return this.filterSuncorMaterialStreams(() => true).map(s => s.id);
  }

  getAllWaterMaterialStreamIds(): string[] {
    return this.filterWaterMaterialStreams(() => true).map(s => s.id);
  }

  getAllMaterialStreamIds(): string[] {
    return this.filterAllMaterialStreams(() => true).map(s => s.id);
  }

  getAllFuelGasMaterialStreamIds(): string[] {
    return this.filterFuelGasMaterialStreams(() => true).map(s => s.id);
  }

  getMaterialStream(id: string): BaseStream {
    return this.materialStreamPool[id];
  }

  getSuncorMaterialStream(id: string): SuncorMaterialStream {
    const s = this.materialStreamPool[id];
    return s instanceof SuncorMaterialStream ? s : undefined;
  }

  getWaterStream(id: string): WaterMaterialStream {
    const s = this.materialStreamPool[id];
    return s instanceof WaterMaterialStream ? s : undefined;
  }

  getFuelGasStream(id: string): FuelGasMaterialStream {
    const s = this.materialStreamPool[id];
    return s instanceof FuelGasMaterialStream ? s : undefined;
  }

  getStreamsByInletUnitOperationId(inletUnitOperationId: string): BaseStream[] {
    return Object.entries(this.materialStreamPool)
      .filter(entry => entry[1].inletUnitOperationId === inletUnitOperationId)
      .map(entry => entry[1]);
  }

  getStreamsByOutletUnitOperationId(outletUnitOperationId: string) {
    return Object.entries(this.materialStreamPool)
      .filter(entry => entry[1].outletUnitOperationId === outletUnitOperationId)
      .map(entry => entry[1]);
  }

  getAttachedStreams(unitOperationId: string): BaseStream[] {
    return Object.entries(this.materialStreamPool)
      .filter(
        entry => entry[1].inletUnitOperationId === unitOperationId || entry[1].outletUnitOperationId === unitOperationId
      )
      .map(entry => entry[1]);
  }

  createFlowsheet(unitOperationId?: string) {
    const flowsheet = new Flowsheet({}, this);
    flowsheet.unitOperationId = unitOperationId;
    // here add code plz
    this.addToPools(flowsheet);
    return flowsheet;
  }

  getFlowsheet(id: string): Flowsheet {
    return this.flowsheetPool[id];
  }

  findFlowsheetByUnitOperationId(id: string): Flowsheet {
    let flowsheet;
    for (const f in this.flowsheetPool) {
      if (this.flowsheetPool[f].unitOperationId === id) {
        flowsheet = this.flowsheetPool[f];
      }
    }
    return flowsheet;
  }

  getOtherBaseObject(id: string): BaseObject {
    return this.otherBaseObjectPool[id];
  }

  getBaseObject(id: string): BaseObject {
    return this.unitOperationPool[id] || this.materialStreamPool[id] || this.otherBaseObjectPool[id];
  }

  removeFromPools(obj: BaseObject): void {
    // delete all nested SimulationVariables...
    const childrenObjectsForRemoval = obj.getChildrenObjectsForRemoval();
    for (const child of childrenObjectsForRemoval) {
      // only remove the simulation variables that belong to the object being removed
      if (child instanceof SimulationVariable) {
        if (child.ownerBaseObject === obj && this.simulationVariablePool[child.id]) {
          this.removeFromPools(child);
        }
      } else {
        this.removeFromPools(child);
      }
    }

    if (obj instanceof UnitOperation) {
      delete this.unitOperationPool[obj.id];
    } else if (obj instanceof SimulationVariable) {
      this.onSimulationVariableDeleted(obj);
      delete this.simulationVariablePool[obj.id];
    } else if (obj instanceof BaseStream) {
      delete this.materialStreamPool[obj.id];
    } else if (obj instanceof Flowsheet) {
      delete this.flowsheetPool[obj.id];
    } else {
      delete this.otherBaseObjectPool[obj.id];
    }

    this.removeComments(obj.id);
  }

  private onSimulationVariableDeleted(sv: SimulationVariable) {
    this.simulationVariableDeletedSubject.next(sv);
    this.multiPeriodSettings.removeMultiPeriodParameterBySimulationVariableId(sv.id);
  }

  deleteUnitOperationById(id: string): void {
    const uo = this.getUnitOperation(id);

    // a recursive call is done here...
    this.deleteFlowsheetByUoId(uo.id);

    // TODO another loop through all the unit ops
    // if the unit op was imported by WaterImportUnitOperation
    if (uo instanceof SteamUseUnitOperation) {
      // remove the water source information streams
      const waterImportUnitOperations = this.filterUnitOperations(
        bwi => bwi instanceof BaseWaterImportUnitOperation
      ) as BaseWaterImportUnitOperation[];

      for (const waterImportUnitOperation of waterImportUnitOperations) {
        waterImportUnitOperation.removeInformationStreamsByProviderUnitOperationId(uo.id);
      }
    }

    // TODO one loop through all the unit ops
    // if the unit op was imported by FuelGasSourceImport
    if (uo instanceof GasContributorUnitOperation || uo instanceof GasExport) {
      const fuelGasSourceImportList = this.filterUnitOperations(
        fg => fg instanceof FuelGasSourceImport
      ) as FuelGasSourceImport[];
      for (const fuelGasSourceImport of fuelGasSourceImportList) {
        fuelGasSourceImport.removeInformationStream(uo.id);
      }

      const fuelGasSinkImportList = this.filterUnitOperations(
        fg => fg instanceof FuelGasSinkImport
      ) as FuelGasSinkImport[];
      for (const fuelGasSinkImport of fuelGasSinkImportList) {
        fuelGasSinkImport.removeEnergyStream(uo.id);
      }
    }

    this.removeFromPools(uo);
  }

  deleteMaterialStreamById(id: string) {
    const ms = this.getMaterialStream(id);
    this.removeFromPools(ms);
  }

  // TODO this should be recursive - to delete level 3 and so - should we fire an observable for this?
  deleteFlowsheetByUoId(unitOperationId: string) {
    const flowsheet = this.findFlowsheetByUnitOperationId(unitOperationId);
    if (flowsheet) {
      this.removeFromPools(flowsheet);
    }
    const subFlowsheetUnitOps = this.findUnitOperationsByFlowsheetId(unitOperationId);

    for (let i = 0; i < subFlowsheetUnitOps.length; i++) {
      this.deleteUnitOperationById(subFlowsheetUnitOps[i].id);
      const streams = this.filterAllMaterialStreams((ms: SuncorMaterialStream) => {
        return (
          ms.inletUnitOperationId === subFlowsheetUnitOps[i].id ||
          ms.outletUnitOperationId === subFlowsheetUnitOps[i].id
        );
      });
      for (const ms of streams) {
        this.deleteMaterialStreamById(ms.id);
      }
    }
  }

  clearResults(): void {
    this.isSolved = false;

    for (const s of Object.keys(this.materialStreamPool)) {
      this.materialStreamPool[s].clearResults();
    }

    for (const u of Object.keys(this.unitOperationPool)) {
      this.unitOperationPool[u].clearResults();
    }
  }

  saveUnitOperation(unitOperation: UnitOperation): void {
    this.unitOperationPool[unitOperation.id].overwriteValues(unitOperation);
  }

  saveCaseSettings(cs: CaseSettings) {
    cs.updateStatus(this.caseSettings);
    cs.convertToInternalUnit();
    this.caseSettings.overwriteValues(cs);
  }

  // region case study manager
  initCaseStudyManagerCategories() {
    // Uncertainty categories
    this.caseStudyManager.addToUncertaintyCategories(
      new CaseStudyCategory('Mine Sources', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.mine.key;
      })
    );
    this.caseStudyManager.addToUncertaintyCategories(
      new CaseStudyCategory('SAGD Sources', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.sagd.key;
      })
    );
    this.caseStudyManager.addToUncertaintyCategories(
      new CaseStudyCategory('Offshore Sources', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.offshore.key;
      })
    );
    this.caseStudyManager.addToUncertaintyCategories(
      new CaseStudyCategory('Diluent sources', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.diluentSource.key;
      })
    );
    this.caseStudyManager.addToUncertaintyCategories(
      new CaseStudyCategory('Upgraders', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.upgrader.key;
      })
    );
    this.caseStudyManager.addToUncertaintyCategories(
      new CaseStudyCategory('Third party sources', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.thirdPartySource.key;
      })
    );
    this.caseStudyManager.addToUncertaintyCategories(
      new CaseStudyCategory('Bitumen conversion', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.bitumenConversion.key;
      })
    );
  }

  initMainFlowsheetParametricStudyCategories() {
    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Diluent sources', (bo: BaseObject): boolean => {
        return bo instanceof DiluentSource;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Refineries', uo => {
        return uo instanceof Refinery;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Case settings', cs => {
        return cs instanceof CaseSettings;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('SAGD Sources', cs => {
        return cs instanceof SagdSource;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Mine Sources', cs => {
        return cs instanceof MineSource;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Third Party Sources', cs => {
        return cs instanceof ThirdPartySource;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Offshore Sources', cs => {
        return cs instanceof OffshoreSource;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Upgraders', cs => {
        return cs instanceof Upgrader;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Bitumen Conversion', cs => {
        return cs instanceof BitumenConversion;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Third Party Refineries', cs => {
        return cs instanceof ThirdPartyRefinery;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Extractions', cs => {
        return cs instanceof Extraction;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Pipes', cs => {
        return cs instanceof Pipe;
      })
    );
  }

  initUpgraderFlowsheetParametricStudyCategories() {
    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Commodity Tanks', cs => {
        return cs instanceof CommodityTank;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Pipes', bo => {
        return bo instanceof Pipe;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('DRUs', cs => {
        return cs instanceof DRU;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('VACs', (bo: BaseObject): boolean => {
        return bo instanceof VAC;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Bitumen Conversion', cs => {
        return cs instanceof BitumenConversion;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Hydrotreaters', cs => {
        return cs instanceof Hydrotreater;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('DCUs', cs => {
        return cs instanceof DCU;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Fluid Cokers', cs => {
        return cs instanceof FluidCoker;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Hydrocrackers', cs => {
        return cs instanceof Hydrocracker;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Sulfur Plants', cs => {
        return cs instanceof SulfurPlant;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Hydrogen Plants', cs => {
        return cs instanceof HydrogenPlant;
      })
    );
  }

  initSteamFlowsheetParametricStudyCategories() {
    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Boilers', cs => {
        return cs instanceof WaterBoiler;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Sources', cs => {
        return cs instanceof WaterSource;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Heat Exchangers', cs => {
        return cs instanceof WaterHeatExchanger;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Turbines', cs => {
        return cs instanceof WaterTurbine;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Heaters', cs => {
        return cs instanceof WaterHeater;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Coolers', cs => {
        return cs instanceof WaterCooler;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Two Phase Separators', cs => {
        return cs instanceof WaterTwoPhaseSeparator;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Pumps', cs => {
        return cs instanceof WaterPump;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Pipes', cs => {
        return cs instanceof WaterPipe;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Cogenerations', cs => {
        return cs instanceof WaterCogeneration;
      })
    );
  }

  initFuelGasFlowsheetParametricStudyCategories() {
    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Gas Sources', cs => {
        return cs instanceof FuelGasSource;
      })
    );

    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Gas Amine Contactors', cs => {
        return cs instanceof FuelGasAmineContactor;
      })
    );
  }

  initExtractionFlowsheetParametricStudyCategories() {
    this.caseStudyManager.addToParametricStudyCategories(
      new CaseStudyCategory('Bitumen Conversion', cs => {
        return cs instanceof BitumenConversion;
      })
    );
  }

  initMainFlowsheetCategories() {
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Dashboard', (bo: BaseObject): boolean => {
        return bo instanceof SuncorCalculator;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Mine Sources', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.mine.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Offshore Sources', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.offshore.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('SAGD Sources', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.sagd.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Third Party Sources', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.thirdPartySource.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Diluent Sources', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.diluentSource.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Upgraders', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.upgrader.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Refineries', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.refinery.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Third Party Refineries', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.thirdPartyRefinery.key;
      })
    );
    this.initStreamsCategory();
  }

  initUpgraderFlowsheetCategories() {
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Commodity Tanks', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.commodityTank.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Product Tanks', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.productTank.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Pipes', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.pipe.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Generic Sinks', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.genericSink.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('DRUs', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.dru.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('VACs', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.vac.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Bitumen conversion', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.bitumenConversion.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Hydrotreaters', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.hydrotreater.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('LGO Hydrotreaters', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.lgoHydrotreater.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('HGO Hydrotreaters', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.hgoHydrotreater.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('DCUs', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.dcu.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Fluid Cokers', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.fluidCoker.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Hydrocrackers', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.hydrocracker.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Sulfur Plants', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.sulfurPlant.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Hydrogen Plants', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.hydrogenPlant.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Light Ends Recovery Units', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.lightEndsRecoveryUnit.key;
      })
    );
    this.initStreamsCategory();
  }

  initSteamFlowsheetCategories() {
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Boilers', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.waterBoiler.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Steam and Power Sources', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.waterSource.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Upgrader Steam Make', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.waterSourceImport.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Upgrader Steam Use', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.waterSinkImport.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Heat Exchangers', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.waterHeatExchanger.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Turbines', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.waterTurbine.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Heaters', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.waterHeater.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Coolers', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.waterCooler.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Two Phase Separators', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.waterTwoPhaseSeparator.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Pumps', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.waterPump.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Valves', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.waterValve.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Steam and Power Sinks', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.waterSink.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Steam and Power Pipes', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.waterPipe.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Steam and Power Pipe Segments', (bo: BaseObject): boolean => {
        return bo instanceof WaterPipeSegment;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Cogenerations', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.waterCogeneration.key;
      })
    );
    this.initStreamsCategory();
  }

  initFuelGasFlowsheetCategories() {
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Gas Sources', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.fuelGasSource.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Flares', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.fuelGasFlare.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Gas Sinks', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.fuelGasSink.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Gas Use', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.fuelGasSinkImport.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Gas Make', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.fuelGasSourceImport.key;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Amine Contactors', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.fuelGasAmineContactor.key;
      })
    );
    this.initStreamsCategory();
  }

  initStreamsCategory() {
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Streams', (bo: BaseObject): boolean => {
        return bo instanceof BaseStream;
      })
    );
    this.caseStudyManager.kpiManager.addToCategories(
      new CaseStudyCategory('Tanks', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.productTank.key;
      })
    );

    // Uncertainty categories
    this.caseStudyManager.addToUncertaintyCategories(
      new CaseStudyCategory('Mine Sources', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.mine.key;
      })
    );
    this.caseStudyManager.addToUncertaintyCategories(
      new CaseStudyCategory('SAGD Sources', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.sagd.key;
      })
    );
    this.caseStudyManager.addToUncertaintyCategories(
      new CaseStudyCategory('Offshore Sources', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.offshore.key;
      })
    );
    this.caseStudyManager.addToUncertaintyCategories(
      new CaseStudyCategory('Diluent sources', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.diluentSource.key;
      })
    );
    this.caseStudyManager.addToUncertaintyCategories(
      new CaseStudyCategory('Upgraders', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.upgrader.key;
      })
    );
    this.caseStudyManager.addToUncertaintyCategories(
      new CaseStudyCategory('Third party sources', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.thirdPartySource.key;
      })
    );
    this.caseStudyManager.addToUncertaintyCategories(
      new CaseStudyCategory('Bitumen conversion', (bo: BaseObject): boolean => {
        return bo.category === unitOperationsConfig.bitumenConversion.key;
      })
    );
  }

  initMultiPeriodCategories() {
    this.multiPeriodSettings.addToCategories(
      new CaseStudyCategory('Unit Operations', (bo: BaseObject): boolean => {
        return bo instanceof UnitOperation;
      })
    );
  }
  // endregion

  addToPools(obj: BaseObject): void {
    if (obj instanceof UnitOperation) {
      this.unitOperationPool[obj.id] = obj;
    } else if (obj instanceof SimulationVariable) {
      this.simulationVariablePool[obj.id] = obj;
    } else if (obj instanceof BaseStream) {
      this.materialStreamPool[obj.id] = obj;
    } else if (obj instanceof Flowsheet) {
      this.flowsheetPool[obj.id] = obj;
    } else {
      this.otherBaseObjectPool[obj.id] = obj;
    }
  }

  unitOperationExistsByName(name: string): boolean {
    for (const uo in this.unitOperationPool) {
      if (this.unitOperationPool[uo].name === name) {
        return true;
      }
    }
    return false;
  }

  removeFluidAnalysis(analysisId) {
    const uos = this.filterUnitOperations(uo => {
      return (uo as any).fluidAnalysisId === analysisId;
    });
    uos.forEach(uo => {
      const unit = uo as any;
      unit.fluidAnalysisId = '';
    });
  }

  calculateUnitOperationName(originalName: string): string {
    let nameIndex = 1;
    for (const uo in this.unitOperationPool) {
      if (this.unitOperationExistsByName(`${originalName} ${nameIndex}`)) {
        nameIndex++;
      }
    }
    return `${originalName} ${nameIndex}`;
  }

  calculateMaterialStreamName(originalName: string) {
    let i;
    for (i = 0; i < Object.keys(this.materialStreamPool).length; i++) {
      const matchingStreams = this.filterAllMaterialStreams(s => s.name === `Stream ${i + 1}`);
      if (!matchingStreams.length) {
        break;
      }
    }
    return `${originalName} ${i + 1}`;
  }

  removeComments(ownerObjectId: string): void {
    const commentsToRemove = this.commentsPool.filter(c => c.ownerId === ownerObjectId);

    const indices = commentsToRemove.map(c => this.commentsPool.indexOf(c));

    for (const commentIndex of indices) {
      this.commentsPool.splice(commentIndex, 1);
    }
  }
  // endregion

  // region getters and setters
  getDiagramString() {
    return this.diagram;
  }

  /**
   * When this is called, the main flowsheet must be active? - to prevent wiping out the main flowsheet diagram
   * @param diagram
   */
  setDiagramString(diagram: string) {
    this.diagram = diagram;
  }

  getActiveFlowsheet() {
    return this.activeFlowsheet;
  }

  setActiveFlowsheet(flowsheet: Flowsheet) {
    this.activeFlowsheet = flowsheet;
  }

  getMainFlowsheetId() {
    return this.flowsheetId;
  }

  /**
   * We won't want to call this often...
   * @param flowsheetId
   */
  setMainFlowsheetId(flowsheetId: string) {
    this.flowsheetId = flowsheetId;
  }
  // endregion

  public toJSON(): any {
    return {
      category: this.category,
      id: this.id,
      name: this.name,
      comments: this.comments,
      isSolved: this.isSolved,
      isReady: this.isReady,
      calculator: this.calculator.id,
      caseSettings: this.caseSettings.id,
      caseStudyManager: this.caseStudyManager.id,
      assayManager: this.assayManager.id,
      objectiveFunction: this.objectiveFunction.id,
      unitOperationPool: this.unitOperationPool,
      materialStreamPool: this.materialStreamPool,
      simulationVariablePool: this.simulationVariablePool,
      otherBaseObjectPool: this.otherBaseObjectPool,
      flowsheetPool: this.flowsheetPool,
      constraintViolationEntries: this.constraintViolationEntries,
      marginalValueEntries: this.marginalValueEntries,
      flowsheetId: this.flowsheetId,
      solveOption: this.solveOption,
      multiPeriodEnabled: this.multiPeriodEnabled,
      multiPeriodSettings: this.multiPeriodSettings.id,
      commentsPool: this.commentsPool,
    };
  }
}
