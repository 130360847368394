import { Injectable } from '@angular/core';
import { Model, Node } from 'gojs';
import * as go from 'gojs';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { AbstractSubFlowsheetPaletteHandler } from './abstract-sub-flowsheet-palette-handler';
import { GoJsPaletteUtils } from './gojs-palette-utils';

export enum UpgraderPaletteSections {
  'GENERAL' = 'generalUpgraderPalette',
  'PROCESSING' = 'processingUpgraderPalette',
  'REACTORS' = 'reactorsUpgraderPalette',
  'UTILITIES_AND_ENVIRONMENTAL' = 'utilitiesAndEnvironmentalUpgraderPalette',
}

@Injectable()
export class UpgraderPaletteHandler extends AbstractSubFlowsheetPaletteHandler {
  general: go.Palette;
  processing: go.Palette;
  reactors: go.Palette;
  utilitiesAndEnvironmental: go.Palette;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(paletteUtils: GoJsPaletteUtils) {
    super(paletteUtils);
  }

  override init(paletteContainerDivId: string, paletteDivId: string) {
    this.paletteContainerDivId = paletteContainerDivId;
    this.paletteDivId = paletteDivId;

    switch (paletteDivId) {
      case UpgraderPaletteSections.GENERAL:
        this.general = this.paletteUtils.initPaletteElement(paletteDivId);
        this.general.nodeTemplate = this.paletteUtils.getPaletteNodeTemplate();
        this.general.model.nodeDataArray = this.getGeneralModel();
        break;

      case UpgraderPaletteSections.PROCESSING:
        this.processing = this.paletteUtils.initPaletteElement(paletteDivId);
        this.processing.nodeTemplate = this.paletteUtils.getPaletteNodeTemplate();
        this.processing.model.nodeDataArray = this.getProcessingModel();
        break;

      case UpgraderPaletteSections.REACTORS:
        this.reactors = this.paletteUtils.initPaletteElement(paletteDivId);
        this.reactors.nodeTemplate = this.paletteUtils.getPaletteNodeTemplate();
        this.reactors.model.nodeDataArray = this.getReactorsModel();
        break;

      case UpgraderPaletteSections.UTILITIES_AND_ENVIRONMENTAL:
        this.utilitiesAndEnvironmental = this.paletteUtils.initPaletteElement(paletteDivId);
        this.utilitiesAndEnvironmental.nodeTemplate = this.paletteUtils.getPaletteNodeTemplate();
        this.utilitiesAndEnvironmental.model.nodeDataArray = this.getUtilitiesAndEnvironmentalModel();
        break;

      default:
        throw new Error(`${paletteDivId} Upgrader palette section was not found.`);
    }
  }

  override updatePalette() {
    this.general.model.nodeDataArray = this.getGeneralModel();
    this.general.requestUpdate();

    this.processing.model.nodeDataArray = this.getProcessingModel();
    this.processing.requestUpdate();

    this.reactors.model.nodeDataArray = this.getReactorsModel();
    this.reactors.requestUpdate();

    this.utilitiesAndEnvironmental.model.nodeDataArray = this.getUtilitiesAndEnvironmentalModel();
    this.utilitiesAndEnvironmental.requestUpdate();
  }

  updatePaletteNodeByAction(category: string, action: string) {
    const node = this.utilitiesAndEnvironmental
      .findNodesByExample({
        category,
      })
      .first();

    const { model } = this.utilitiesAndEnvironmental;
    action === 'enable' ? this.enableNode(model, node) : this.disableNode(model, node);
  }

  enableNode(model: Model, node: Node) {
    model.startTransaction('updateNode');
    model.setDataProperty(node.data, 'textColor', '#000000');
    model.setDataProperty(node.data, 'backgroundColor', 'Transparent');
    model.setDataProperty(node.data, 'selectable', true);
    model.commitTransaction('updateNode');
  }

  disableNode(model: Model, node: Node) {
    model.startTransaction('updateNode');
    model.setDataProperty(node.data, 'textColor', '#698795');
    model.setDataProperty(node.data, 'backgroundColor', '#96c1d6');
    model.setDataProperty(node.data, 'selectable', false);
    model.commitTransaction('updateNode');
  }

  getGeneralModel(): any[] {
    return [
      {
        name: unitOperationsConfig.upgraderInlet.displayName,
        icon: unitOperationsConfig.upgraderInlet.paletteIcon,
        diagramIcon: unitOperationsConfig.upgraderInlet.diagramIcon,
        category: unitOperationsConfig.upgraderInlet.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.commodityTank.displayName,
        icon: unitOperationsConfig.commodityTank.paletteIcon,
        diagramIcon: unitOperationsConfig.commodityTank.diagramIcon,
        category: unitOperationsConfig.commodityTank.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.productTank.displayName,
        icon: unitOperationsConfig.productTank.paletteIcon,
        diagramIcon: unitOperationsConfig.productTank.diagramIcon,
        category: unitOperationsConfig.productTank.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.pipe.displayName,
        icon: unitOperationsConfig.pipe.paletteIcon,
        diagramIcon: unitOperationsConfig.pipe.diagramIcon,
        category: unitOperationsConfig.pipe.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.mixer.displayName,
        icon: unitOperationsConfig.mixer.paletteIcon,
        diagramIcon: unitOperationsConfig.mixer.diagramIcon,
        category: unitOperationsConfig.mixer.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.splitter.displayName,
        icon: unitOperationsConfig.splitter.paletteIcon,
        diagramIcon: unitOperationsConfig.splitter.diagramIcon,
        category: unitOperationsConfig.splitter.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.genericSink.displayName,
        icon: unitOperationsConfig.genericSink.paletteIcon,
        diagramIcon: unitOperationsConfig.genericSink.diagramIcon,
        category: unitOperationsConfig.genericSink.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.gasExport.displayName,
        icon: unitOperationsConfig.gasExport.paletteIcon,
        diagramIcon: unitOperationsConfig.gasExport.diagramIcon,
        category: unitOperationsConfig.gasExport.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
    ];
  }

  getProcessingModel(): any[] {
    return [
      {
        name: unitOperationsConfig.dru.displayName,
        icon: unitOperationsConfig.dru.paletteIcon,
        diagramIcon: unitOperationsConfig.dru.diagramIcon,
        category: unitOperationsConfig.dru.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.vac.displayName,
        icon: unitOperationsConfig.vac.paletteIcon,
        diagramIcon: unitOperationsConfig.vac.diagramIcon,
        category: unitOperationsConfig.vac.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.bitumenConversion.displayName,
        icon: unitOperationsConfig.bitumenConversion.paletteIcon,
        diagramIcon: unitOperationsConfig.bitumenConversion.diagramIcon,
        category: unitOperationsConfig.bitumenConversion.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.lightEndsRecoveryUnit.displayName,
        icon: unitOperationsConfig.lightEndsRecoveryUnit.paletteIcon,
        diagramIcon: unitOperationsConfig.lightEndsRecoveryUnit.diagramIcon,
        category: unitOperationsConfig.lightEndsRecoveryUnit.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
    ];
  }

  getReactorsModel(): any[] {
    return [
      {
        name: unitOperationsConfig.hydrotreater.displayName,
        icon: unitOperationsConfig.hydrotreater.paletteIcon,
        diagramIcon: unitOperationsConfig.hydrotreater.diagramIcon,
        category: unitOperationsConfig.hydrotreater.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.lgoHydrotreater.displayName,
        icon: unitOperationsConfig.lgoHydrotreater.paletteIcon,
        diagramIcon: unitOperationsConfig.lgoHydrotreater.diagramIcon,
        category: unitOperationsConfig.lgoHydrotreater.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.hgoHydrotreater.displayName,
        icon: unitOperationsConfig.hgoHydrotreater.paletteIcon,
        diagramIcon: unitOperationsConfig.hgoHydrotreater.diagramIcon,
        category: unitOperationsConfig.hgoHydrotreater.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.dcu.displayName,
        icon: unitOperationsConfig.dcu.paletteIcon,
        diagramIcon: unitOperationsConfig.dcu.diagramIcon,
        category: unitOperationsConfig.dcu.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.fluidCoker.displayName,
        icon: unitOperationsConfig.fluidCoker.paletteIcon,
        diagramIcon: unitOperationsConfig.fluidCoker.diagramIcon,
        category: unitOperationsConfig.fluidCoker.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.hydrocracker.displayName,
        icon: unitOperationsConfig.hydrocracker.paletteIcon,
        diagramIcon: unitOperationsConfig.hydrocracker.diagramIcon,
        category: unitOperationsConfig.hydrocracker.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
    ];
  }

  getUtilitiesAndEnvironmentalModel(): any[] {
    return [
      {
        name: unitOperationsConfig.waterUtilityUnitOperation.displayName,
        icon: unitOperationsConfig.waterUtilityUnitOperation.paletteIcon,
        diagramIcon: unitOperationsConfig.waterUtilityUnitOperation.diagramIcon,
        category: unitOperationsConfig.waterUtilityUnitOperation.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.sulfurPlant.displayName,
        icon: unitOperationsConfig.sulfurPlant.paletteIcon,
        diagramIcon: unitOperationsConfig.sulfurPlant.diagramIcon,
        category: unitOperationsConfig.sulfurPlant.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.hydrogenPlant.displayName,
        icon: unitOperationsConfig.hydrogenPlant.paletteIcon,
        diagramIcon: unitOperationsConfig.hydrogenPlant.diagramIcon,
        category: unitOperationsConfig.hydrogenPlant.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.fuelGasUtilityUnitOperation.displayName,
        icon: unitOperationsConfig.fuelGasUtilityUnitOperation.paletteIcon,
        diagramIcon: unitOperationsConfig.fuelGasUtilityUnitOperation.diagramIcon,
        category: unitOperationsConfig.fuelGasUtilityUnitOperation.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
    ];
  }

  getProcessModel(): any[] {
    return [
      {
        name: unitOperationsConfig.upgraderInlet.displayName,
        icon: unitOperationsConfig.upgraderInlet.paletteIcon,
        diagramIcon: unitOperationsConfig.upgraderInlet.diagramIcon,
        category: unitOperationsConfig.upgraderInlet.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.dru.displayName,
        icon: unitOperationsConfig.dru.paletteIcon,
        diagramIcon: unitOperationsConfig.dru.diagramIcon,
        category: unitOperationsConfig.dru.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.vac.displayName,
        icon: unitOperationsConfig.vac.paletteIcon,
        diagramIcon: unitOperationsConfig.vac.diagramIcon,
        category: unitOperationsConfig.vac.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.mixer.displayName,
        icon: unitOperationsConfig.mixer.paletteIcon,
        diagramIcon: unitOperationsConfig.mixer.diagramIcon,
        category: unitOperationsConfig.mixer.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.splitter.displayName,
        icon: unitOperationsConfig.splitter.paletteIcon,
        diagramIcon: unitOperationsConfig.splitter.diagramIcon,
        category: unitOperationsConfig.splitter.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.hydrotreater.displayName,
        icon: unitOperationsConfig.hydrotreater.paletteIcon,
        diagramIcon: unitOperationsConfig.hydrotreater.diagramIcon,
        category: unitOperationsConfig.hydrotreater.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.hgoHydrotreater.displayName,
        icon: unitOperationsConfig.hgoHydrotreater.paletteIcon,
        diagramIcon: unitOperationsConfig.hgoHydrotreater.diagramIcon,
        category: unitOperationsConfig.hgoHydrotreater.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.lgoHydrotreater.displayName,
        icon: unitOperationsConfig.lgoHydrotreater.paletteIcon,
        diagramIcon: unitOperationsConfig.lgoHydrotreater.diagramIcon,
        category: unitOperationsConfig.lgoHydrotreater.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },

      {
        name: unitOperationsConfig.dcu.displayName,
        icon: unitOperationsConfig.dcu.paletteIcon,
        diagramIcon: unitOperationsConfig.dcu.diagramIcon,
        category: unitOperationsConfig.dcu.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.fluidCoker.displayName,
        icon: unitOperationsConfig.fluidCoker.paletteIcon,
        diagramIcon: unitOperationsConfig.fluidCoker.diagramIcon,
        category: unitOperationsConfig.fluidCoker.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.bitumenConversion.displayName,
        icon: unitOperationsConfig.bitumenConversion.paletteIcon,
        diagramIcon: unitOperationsConfig.bitumenConversion.diagramIcon,
        category: unitOperationsConfig.bitumenConversion.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.genericSink.displayName,
        icon: unitOperationsConfig.genericSink.paletteIcon,
        diagramIcon: unitOperationsConfig.genericSink.diagramIcon,
        category: unitOperationsConfig.genericSink.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.commodityTank.displayName,
        icon: unitOperationsConfig.commodityTank.paletteIcon,
        diagramIcon: unitOperationsConfig.commodityTank.diagramIcon,
        category: unitOperationsConfig.commodityTank.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.productTank.displayName,
        icon: unitOperationsConfig.productTank.paletteIcon,
        diagramIcon: unitOperationsConfig.productTank.diagramIcon,
        category: unitOperationsConfig.productTank.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.hydrocracker.displayName,
        icon: unitOperationsConfig.hydrocracker.paletteIcon,
        diagramIcon: unitOperationsConfig.hydrocracker.diagramIcon,
        category: unitOperationsConfig.hydrocracker.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.pipe.displayName,
        icon: unitOperationsConfig.pipe.paletteIcon,
        diagramIcon: unitOperationsConfig.pipe.diagramIcon,
        category: unitOperationsConfig.pipe.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
    ];
  }

  getPaletteModel(): any[] {
    return [];
  }
}
