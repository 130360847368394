import { Component, Input, OnInit } from '@angular/core';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';
import { FluidAnalysis } from '../../../_models/_fluid/fluid-analysis';

@Component({
  selector: 'sob-cut-properties',
  templateUrl: './cut-properties.component.html',
  styleUrls: ['./cut-properties.component.css'],
})
export class CutPropertiesComponent implements OnInit {
  @Input() fluidAnalysis: FluidAnalysis;

  suncorFluidAnalysis: SuncorFluidAnalysis;

  @Input()
  readonly: boolean;

  @Input()
  idPrefix: string;

  ngOnInit() {
    this.readonly = this.readonly ? this.readonly : false;
    this.idPrefix = this.idPrefix ? this.idPrefix : '';

    this.suncorFluidAnalysis = this.fluidAnalysis as SuncorFluidAnalysis;
  }
}
