import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractFormGroupPropertyWindow } from '../abstract-form-group-property-window';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import { LightEndsRecoveryUnit } from '../../../_models/_unit-operations/upgrader/light-ends-recovery-unit';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';

@Component({
  selector: 'sob-light-ends-recovery-unit',
  templateUrl: './light-ends-recovery-unit.component.html',
  styleUrls: ['./light-ends-recovery-unit.component.css'],
})
export class LightEndsRecoveryUnitComponent extends AbstractFormGroupPropertyWindow implements OnInit {
  @Input() unitOperation: LightEndsRecoveryUnit;
  @Input() formGroupWrapper!: BaseObjectFormGroupWrapper;

  private readonly svfb = inject(SimVarFormBuilder);

  formGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    butaneRecovery: this.svfb.emptyControl(),
    removalFactor: this.svfb.emptyControl(),
    ghgEmissions: this.svfb.emptyControl(true),
    opexVar: this.svfb.emptyControl(),
    opexGasDiesel: this.svfb.emptyControl(),
    opexPower: this.svfb.emptyControl(),
    opexCarbonDioxide: this.svfb.emptyControl(true),
    opexFixed: this.svfb.emptyControl(),
    opexTotal: this.svfb.emptyControl(true),

    capexAmortized: this.svfb.emptyControl(),
    capexTotal: this.svfb.emptyControl(true),
    capacity: this.svfb.emptyConstraintControl(),
    minimumFlow: this.svfb.emptyConstraintControl(),
  });

  ngOnInit(): void {
    this.init();
  }

  addControls() {
    this.formGroup.controls.name.patchValue(this.unitOperation.name);
    this.svfb.patchValue(this.unitOperation.butaneRecovery, this.formGroup.controls.butaneRecovery);
    this.svfb.patchValue(this.unitOperation.removalFactor, this.formGroup.controls.removalFactor);
    this.svfb.patchValue(this.unitOperation.ghgEmissions, this.formGroup.controls.ghgEmissions);
    this.svfb.patchValue(this.unitOperation.opexVar, this.formGroup.controls.opexVar);
    this.svfb.patchValue(this.unitOperation.opexGasDiesel, this.formGroup.controls.opexGasDiesel);
    this.svfb.patchValue(this.unitOperation.opexPower, this.formGroup.controls.opexPower);
    this.svfb.patchValue(this.unitOperation.opexCarbonDioxide, this.formGroup.controls.opexCarbonDioxide);
    this.svfb.patchValue(this.unitOperation.opexFixed, this.formGroup.controls.opexFixed);
    this.svfb.patchValue(this.unitOperation.opexTotal, this.formGroup.controls.opexTotal);
    this.svfb.patchValue(this.unitOperation.capexAmortized, this.formGroup.controls.capexAmortized);
    this.svfb.patchValue(this.unitOperation.capexTotal, this.formGroup.controls.capexTotal);
    this.svfb.patchConstraintValue(this.unitOperation.capacity, this.formGroup.controls.capacity);
    this.svfb.patchConstraintValue(this.unitOperation.minimumFlow, this.formGroup.controls.minimumFlow);

    for (const key of Object.keys(this.formGroup.controls)) {
      this.propertyWindowFormGroup.addControl(key, this.formGroup.controls[key]);
    }
  }
}
