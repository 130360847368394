import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder } from '@angular/forms';
import { SubSink } from 'subsink';
import { GasExport } from '../../../_models/_unit-operations/upgrader/gas-export';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import { CoreService } from '../../../_services/core.service';
import { GasExportDestination } from '../../../_config/unit-operations/gas-export-destination.enum';
import { FuelGasUtilityUnitOperation } from '../../../_models/_unit-operations/fuel-gas/fuel-gas-utility-unit-operation';

@Component({
  selector: 'sob-gas-export',
  templateUrl: './gas-export.component.html',
  styleUrls: ['./gas-export.component.css'],
})
export class GasExportComponent implements OnInit {
  @Input() unitOperation: GasExport;
  @Input() formGroupWrapper: BaseObjectFormGroupWrapper;

  protected readonly GasExportDestination = GasExportDestination;
  fuelGasSubFlowsheetOwnerName = '';
  fuelGasSubFlowsheetOwnerId = '';
  private subSink = new SubSink();

  private readonly fb = inject(UntypedFormBuilder);
  private readonly coreService = inject(CoreService);

  constructor() {
    this.subSink.add(
      this.coreService.unitOperationRemovedRequest.subscribe(uo => {
        if (uo instanceof FuelGasUtilityUnitOperation) {
          this.setDestinationDefaultValue();
        }
      })
    );
  }

  ngOnInit(): void {
    this.addControls();
    this.formGroupWrapper.storeOriginalValue();
  }

  addControls() {
    this.propertyWindowFormGroup.addControl('name', this.fb.control(this.unitOperation.name));
    this.propertyWindowFormGroup.addControl('destination', this.fb.control(this.unitOperation.destination));

    if (!this.getFuelGasSubFlowsheetOwner()) {
      this.setDestinationDefaultValue();
    }
  }

  get propertyWindowFormGroup() {
    return this.formGroupWrapper.getFormGroup();
  }

  get destinationFormControl() {
    return this.propertyWindowFormGroup.controls['destination'] as FormControl;
  }

  setDestinationDefaultValue() {
    this.destinationFormControl.setValue(GasExportDestination.SINK);
    this.destinationFormControl.disable();
  }

  getFuelGasSubFlowsheetOwner() {
    const gasSubFlowsheetOwner = this.coreService.currentCase.filterUnitOperations(uo => {
      return uo.flowsheetId === this.unitOperation.flowsheetId && uo instanceof FuelGasUtilityUnitOperation;
    });
    if (gasSubFlowsheetOwner.length) {
      this.fuelGasSubFlowsheetOwnerName = gasSubFlowsheetOwner[0].name;
      this.fuelGasSubFlowsheetOwnerId = gasSubFlowsheetOwner[0].id;
      return true;
    }
    return false;
  }
}
