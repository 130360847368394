<ul class="nav nav-tabs tab-margin" role="tablist">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">Model</a>
  </li>

  <li role="presentation">
    <a href="#gasExportComments" aria-controls="gasExportComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg {{ this.unitOperation.comments ? 'gentle-hover-shake' : '' }}" aria-hidden="true">
      </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding" [formGroup]="propertyWindowFormGroup">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <div class="form-group">
          <label for="destination" class="pw-label control-label col-sm-4">Destination</label>
          <div class="col-sm-6">
            <select id="destination" class="form-control" formControlName="destination">
              <option value="{{ GasExportDestination.SINK }}">Sink</option>
              <ng-container *ngIf="getFuelGasSubFlowsheetOwner()">
                <option value="{{ fuelGasSubFlowsheetOwnerId }}">{{ fuelGasSubFlowsheetOwnerName }}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="gasExportComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
